.templatesGrid {
    text-align: left;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    /* width: calc(100% - 50px); */
    /* margin-left: 30px; */
    padding: 40px 50px 50px;
    /* margin: 20px 50px; */
    /* width: calc(100% - 60px); */
    row-gap: 60px;
    background-color: white;
    border-radius: 10px;

    position: relative;
    top: -30px;
    padding-top: 50px;
}

.templatesGrid.landscape {
    column-gap: calc((100% - (3*280px)) / 2);
}

.templatesGrid.portrait {
    column-gap: calc((100% - (3*160px)) / 2);
    padding-left: 125px;
    padding-right: 125px;
}

.templatesGrid>.gridItemHeight {
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    position: relative;
}

.templatesGrid.landscape>.gridItemHeight {
    height: 200px;
}

.templatesGrid.portrait>.gridItemHeight {
    height: 320px;
}

.templatesGrid>.gridItemHeight>span.title {
    display: block;
    text-align: center;
    width: 100%;
    font-size: 12px;
}

.templatesGrid>.gridItemHeight>span.buttons,
#selectedTemplate>.center>span.buttons {

    text-align: center;
    width: 100%;


    position: absolute;
    bottom: 0;
    line-height: 18px;
    font-size: 10px;
}

.templatesGrid>.gridItemHeight>span.buttons .button,
#selectedTemplate>.center>span.buttons .button {
    background-color: lightgrey;
    display: inline-block;
    border-radius: 5px;
    cursor: pointer;
    width: 50px;
    margin: 0 5px;
    /* border: none; */
    /* font-size: 10px; */
    /* line-height: 18px; */
}
.templatesGrid>.gridItemHeight>span.buttons .button.asked,
#selectedTemplate>.center>span.buttons .button.asked  {
    background-color: lightgreen;
    width: 70px;
    cursor: unset;
}


.templatesGrid>.gridItemHeight>div {
    transform: scale(0.14);
    transform-origin: top left;

    outline: 20px solid black;
    margin-left: 5px;
    margin-top: 5px;

    overflow: hidden; 
    display: inline-table;
}

.templatesGrid.landscape>.gridItemHeight>div {
    height: 1080px
}

.templatesGrid.portrait>.gridItemHeight>div {
    height: 1920px
}

/* Show function */
.templatesGrid>.gridItemHeight>div:hover {
    /* transform: scale(1); */
    filter: brightness(80%);
    cursor: pointer;
}



/* .gridItemHeight.selected {
    grid-column: 1 / 4;
    position: absolute;
    height: auto;
}

.gridItemHeight.selected>div {
    transform: scale(0.4);
} */
#selectedTemplate
{
    position: absolute;
    left: 0;
}
#selectedTemplate>.center {
    width: 880px;
    text-align: left;
    background-color: white;
    height: 560px;
    top: 80px;
    z-index: 4;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px;
      max-width: 90vw;
}
#selectedTemplate.portrait>.center {
    height: 590px;
}
#selectedTemplate>.center>div.slide.rotation {

    margin-top: 70px;
    transform-origin: top left;

    border: 10px solid black;

    overflow: hidden; 
    display: inline-table;
    background-color: #ededed;

    transform: scale(0.35) translateX(-50%);
    margin-left: 50%;
}

#selectedTemplate.portrait>.center>div.slide.rotation {
    transform: scale(0.22) translateX(-50%);
}
#selectedTemplate>.center>img:hover
{
    background-color: lightgrey;
}

#selectedTemplate>.center>img.chevron-left,
#selectedTemplate>.center>img.chevron-right,
#selectedTemplate>.center>div.times
{
    height: 60px;
    width: 20px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.2);
    cursor: pointer;
}
#selectedTemplate>.center>img.chevron-left:hover,
#selectedTemplate>.center>img.chevron-right:hover,
#selectedTemplate>.center>div.times:hover
{
    background-color: rgba(0, 0, 0, 0.1);
}
#selectedTemplate>.center>div.times
{
    right: 15px;
    top: 10px;
    height: 20px;
    width: 20px;
}
#selectedTemplate>.center>img.chevron-left
{ 
    top: calc(50% - 20px);
    transform: translateY(-50%);
    left: 30px;
}
#selectedTemplate>.center>img.chevron-right
{
    top: calc(50% - 20px);
    transform: translateY(-50%);
    right: 30px;
}
#selectedTemplate>.center>span.title
{
    display: block;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    font-family: 'Poppins', 'Ubuntu', sans-serif;
    padding-bottom: 5px;
    margin-top: 5px;

    border-bottom: 2px solid red;
}
#selectedTemplate>.center>span.underNumberOf
{
    display: block;
    position: absolute;
    bottom: 20px;
     left: 50%;
    transform: translateX(-50%);
    color: rgba(0, 0, 0, 0.2);;
    font-family: 'Poppins', 'Ubuntu', sans-serif;
}
#selectedTemplate>.center>span.buttons
{
    bottom: 60px;
    line-height: 25px;
    font-size: 15px;
    font-family: 'Poppins', 'Ubuntu', sans-serif;
}
#selectedTemplate.portrait>.center>span.buttons{
    bottom: 50px;
}
#selectedTemplate>.center>span.buttons .button
{
    width: 90px;
}
#selectedTemplate>.center>span.buttons .button.asked
{
    width:120px;
}
#selectedTemplate>.center>span.buttons .button:not(.asked):hover
{
    opacity: 0.7;
}



/* .invisibleForm
{
    width: fit-content;
    display: inline-block;
} */


@media (max-width: 1000px) {
    .templatesGrid.landscape {
        grid-template-columns: 1fr 1fr;
        column-gap: calc((100% - (2*280px)) / 1);
    }
}
@media (max-width: 870px) {
    #selectedTemplate.landscape>.center>div.slide.rotation {
        transform: scale(0.2) translateX(-50%);
    }
    #selectedTemplate.landscape>.center {
        height: 410px;
    }
}
@media (max-width: 720px) {
    .templatesGrid.landscape {
        grid-template-columns: 1fr;
    }
    .templatesGrid.landscape .gridItemHeight
    {
        width: 280px;
    }
}

@media (max-width: 800px) {
    .templatesGrid.portrait {
        grid-template-columns: 1fr 1fr;
        column-gap: calc((100% - (2*160px)) / 1);
    }
}
@media (max-width: 550px) {
    #selectedTemplate.landscape>.center>div.slide.rotation {
        transform: scale(0.12) translateX(-50%);
    }
    #selectedTemplate.landscape>.center {
        height: 350px;
    }

}
@media (max-width: 630px) {
    .templatesGrid.portrait {
        grid-template-columns: 1fr;       
    }
    .templatesGrid.portrait .gridItemHeight
    {
        width: 160px;
    }
}

/* Bij scherm groter dan laptop */
@media (min-height: 900px) and (min-width: 1200px) {
    #selectedTemplate.portrait>.center {
        height: 760px;
        width: 950px;
    }
  
    #selectedTemplate>.center>div.slide.rotation
    {
        transform: scale(0.5) translateX(-50%);
        margin-top: 75px;
    }
    #selectedTemplate.portrait>.center>div.slide.rotation {
        transform: scale(0.30) translateX(-50%);
    }
    #selectedTemplate>.center
    {
        width: 1200px;
        height: 740px;
    }
    #selectedTemplate>.center>span.title
    {
        font-size: 20px;
    }
}