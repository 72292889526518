#vmts-00 .container {
    max-width: none;
    width: 1920px;
    height: 1080px;
    padding: 0px;
    display: flex;
    background: #ededed;
}

#vmts-00 .header-slide .logo {
    /* filter: drop-shadow(0px 0px 3px #000); */
    position: fixed;
    height: 60px;
    top: 30px;
    right: 132px;
}

#vmts-00 .header-slide {
    display: flex;
    position: fixed;
    height: 351px;
    width: 100%;
    top: 0;
    left: 0;
}

#vmts-00 .content-slide {
    position: relative;
    display: flex;
    width: 1656px;
    height: 858px;
    padding: 0;
    margin: 125px auto 0 auto;
}

/*****************
    HOUSE CONTENT
*****************/

#vmts-00 .content-slide .house {
    height: 541px;
    width: 1676px;
    border-radius: 10px;
    position: relative;
    background-color: white;
}

#vmts-00 .content-slide .house .main-picture {
    width: calc(50% + 12px);
    height: calc(100% + 40px);
    left: -20px;
    top: -20px;
    position: absolute;
}

#vmts-00 .content-slide .house .main-picture .bg-img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    border-radius: 10px;
    box-shadow: -10px 10px 30px rgba(0, 0, 0, 0.25);
}

#vmts-00 .content-slide .bottom-pictures {
    height: 269px;
    width: calc(100% + 40px);
    position: absolute;
    left: -20px;
    bottom: 0px;
}

#vmts-00 .content-slide .bottom-pictures .s-img {
    display: inline-block;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    width: 407px;
    height: 100%;
    border-radius: 10px;
    margin-left: 22.6px;
    box-shadow: -10px 10px 30px rgba(0, 0, 0, 0.25);
}

#vmts-00 .content-slide .bottom-pictures .s-img:first-of-type {
    margin-left: 0;
}

#vmts-00 .content-slide .house .main-picture .status {
    position: absolute;
    left: -15px;
    top: 30px;
    line-height: 60px;
    text-align: center;
    vertical-align: middle;
    font-size: 30px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 10px;
    box-shadow: -19px 18px 34.8px 5.2px rgba(2, 4, 47, 0.25);
}

#vmts-00 .content-slide .house .text-container {
    position: absolute;
    right: 0;
    width: 835px;
    height: 100%;
    padding: 60px 72px;
}

#vmts-00 .content-slide .house .text-container .address {
    line-height: 50px;
    font-size: 40px;
    font-weight: bolder;
}

#vmts-00 .content-slide .house .text-container .price {
    margin-top: 10px;
    font-weight: bold;
    font-size: 30px;
    font-style: italic;
}

#vmts-00 .content-slide .house .text-container table {
    width: 100%;
    font-size: 20px;
    position: absolute;
    font-size: 20px;
    line-height: 45px;
    top: 275px;
    color: #1e3052;
}

#vmts-00 .content-slide .house .text-container table th {
    width: 265px;
}

#vmts-00 .content-slide .house .text-container table td {
    font-weight: 300;
}

/* #vmts-00 .poweredByViewie {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 180px;
    height: 40px;
} */

/* @media (orientation: portrait) { */
.portrait #vmts-00 .container {
    max-width: none;
    height: 1920px;
    width: 1080px;
}

.portrait #vmts-00 .header-slide .logo {
    top: 17.5px;
    height: 55px;
    right: 50%;
    transform: translateX(50%);
}

.portrait #vmts-00 .content-slide {
    width: 90%;
    height: 1760px;
    margin-top: 110px;
}

.portrait #vmts-00 .content-slide .house {
    height: 1160px;
}

.portrait #vmts-00 .content-slide .house .main-picture {
    width: calc(100% + 40px);
    height: 650px;
}

.portrait #vmts-00 .content-slide .house .text-container {
    width: 100%;
    height: 530px;
    bottom: 0;
    padding-top: 45px;
}

.portrait #vmts-00 .content-slide .house .text-container table {
    top: 230px;
}

.portrait #vmts-00 .content-slide .house .text-container table th {
    width: 350px;
}

.portrait #vmts-00 .content-slide .bottom-pictures {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    height: 575px;
}

.portrait #vmts-00 .content-slide .bottom-pictures .s-img {
    width: 100%;
    margin: 0px;
}

/* } */