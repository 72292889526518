/*****************
    GLOBAL ELEMENTS
*****************/

#vmts-03 .container {
    max-width: none;
    width: 1920px;
    height: 1080px;
    padding: 0;
    display: flex;
    background-color: #ededed;
}

#vmts-03 .header-slide {
    display: flex;
    position: fixed;
    height: 350px;
    width: 100%;
    top: 0;
}

#vmts-03 .header-slide .logo {
    /* filter: drop-shadow(0px 0px 3px #000); */
    position: fixed;
    height: 55px;
    top: 25px;
    right: 50%;
    transform: translateX(50%);
}

#vmts-03 .content-slide {
    width: 1683px;
    padding: 0;
    margin: 131px auto 0 134px;
}

#vmts-03 .content-slide .top-row,
#vmts-03 .content-slide .bottom-row {
    height: 365px;
}

/* nth-of-type(2) is are the houses on the right */
#vmts-03 .content-slide .top-row .house:nth-of-type(2),
#vmts-03 .content-slide .bottom-row .house:nth-of-type(2) {
    float: right
}

#vmts-03 .content-slide .bottom-row {
    margin-top: 97px;
}

/*****************
    HOUSE CONTENT
*****************/

#vmts-03 .content-slide .house {
    height: 365px;
    width: 810px;
    border-radius: 10px;
    position: relative;
    display: inline-block;
    background-color: #fff;
}

#vmts-03 .content-slide .house .picture-container {
    height: 414px;
    width: 359px;
    position: relative;
    margin-left: -25px;
    margin-top: -25px;
}

#vmts-03 .content-slide .house .picture-container .bottom-pictures {
    height: 112px;
    width: 100%;
    position: absolute;
    bottom: 0;
}

#vmts-03 .content-slide .house .picture-container .main-picture {
    width: 100%;
    height: 285px;
    top: 0;
    position: absolute;
}

#vmts-03 .content-slide .house .picture-container .main-picture .bg-img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    border-radius: 10px;
    box-shadow: -10px 10px 30px rgba(0, 0, 0, 0.25);
}

#vmts-03 .content-slide .house .picture-container .bottom-pictures .s-img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 170px;
    height: 100%;
    position: absolute;
    border-radius: 10px;
    box-shadow: -10px 10px 30px rgba(0, 0, 0, 0.25);
}

#vmts-03 .content-slide .house .picture-container .bottom-pictures .s-img-left {
    left: 0;
}

#vmts-03 .content-slide .house .picture-container .bottom-pictures .s-img-right {
    right: 0;
}

#vmts-03 .content-slide .house .picture-container .main-picture .status {
    position: absolute;
    left: -15px;
    top: 15px;
    line-height: 45px;
    text-align: center;
    font-size: 20px;
    border-radius: 10px;
    padding-left: 15px;
    padding-right: 15px;
    box-shadow: -19px 18px 34.8px 5.2px rgba(2, 4, 47, 0.25);
    font-weight: bold;
    text-transform: uppercase;
}

#vmts-03 .content-slide .house .text-container {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 478px;
    height: 100%;
    padding: 50px 20px 20px 50px;
}

#vmts-03 .content-slide .house .text-container .price {
    font-weight: bold;
    font-size: 20px;
    color: orange;
    font-style: italic;
    margin-top: 10px;
}

#vmts-03 .content-slide .house .text-container .address {
    font-weight: bolder;
    font-size: 25px;

}

#vmts-03 .content-slide .house .text-container table {
    position: absolute;
    top: 195px;
    color: #1e3052;
    font-size: 20px;
}

#vmts-03 .content-slide .house .text-container table tr {
    height: 45px;
}

#vmts-03 .content-slide .house .text-container table th {
    width: 212px;
}

#vmts-03 .content-slide .house .text-container table td {
    font-weight: 300;
}


/* #vmts-03 .poweredByViewie {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 180px;
    height: 40px;
} */




    .portrait #vmts-03 .container {
        width: 1080px;
        height: 1920px;
    }

    .portrait #vmts-03 .container .content-slide {
        width: 972px;
        margin: 31px auto 0 66.5px;
    }

    .portrait #vmts-03 .content-slide .top-row .house:nth-of-type(2),
    .portrait #vmts-03 .content-slide .bottom-row .house:nth-of-type(2) {
        float: left;
    }

    .portrait #vmts-03 .content-slide .top-row,
    .portrait #vmts-03 .content-slide .bottom-row {
        margin-top: 0px;
    }

    .portrait #vmts-03 .content-slide .house {
        margin-top: 80px;
        width: 950px;
    }

    .portrait #vmts-03 .content-slide .house .text-container {
        width: 615px;
        padding-top: 30px;
    }

    .portrait #vmts-03 .content-slide .house .text-container .address {
        font-size: 30px
    }
