.topbar1 {
    height: 100px;
    background: white;
    text-align: center;
    width: 100vw;
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
}

.topbar1 .logo {
    height: 80px;
    margin-top: 10px;
}

.topbar2 {
    margin: 40px auto;
    position: relative;
    font-size: 20px;
    width: calc(100% - 100px);
}

.topbar2 div {
    margin-bottom: 5px;
}

.topbar2 .logo {
    position: absolute;
    top: 0;
    right: 0;
    height: 60px;
}

.topbar_red,
.BottomBarRed {
    background: linear-gradient(140deg, #cf007f 0px, #0087c0 100%);
    line-height: 40px;
    width: calc(100% - 100px);
    margin: auto;
    border-radius: 5px;
    color: white;
    padding: 10px 30px;
    position: relative;
    z-index: 2;
    font-size: 14px;
    display: flow-root;
    overflow: hidden;
}
.topbar_red>span.nowrap
{
    white-space: nowrap;
}
.BottomBarRed
{
    margin-top: 70px;
    text-align: center;
    padding: 10px 5px;
}


.topbar_red .switch {
    margin-left: 20px;
}

.topbar_red .switch>span {
    padding: 5px 9px;
    margin-right: 5px;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid rgba(0,0,0,0.3);
}

.topbar_red .style
 {
    padding: 5px 10px;
    float: right;
    background-color: rgba(0,0,0,0.3);
    border-radius: 5px;
    cursor: pointer;

    height: 30.4px;
    line-height: 20px;
    margin: 4.1px 0px;

}


.topbar_red .switch>span.selected {
    background-color: rgba(0,0,0,0.3);

}



/*  */
.bottomBar 
{
    width: 100vw;
    background-color: #4D4D4D;
    color: white;
    position: absolute;
    bottom: 0;
    padding: 0 50px;
}
.bottomBar .inner-bottom-bar{
    
    width: fit-content;
    max-width: 910px;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    white-space: nowrap;
    text-align: center;
    font-size: 12px;
    column-gap: 30px;
    line-height: 100px;
}

.bottomBar img {
    height: 65px;
    margin: auto;
}
.BottomBarRed .style {
    width: fit-content;
    background-color: rgba(0,0,0,0.3);
    padding: 0 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 20px;
    display: inline-block;
}

@media(max-width: 800px){
    .bottomBar .inner-bottom-bar
    {
        font-size: 10px;
    }
    .bottomBar .inner-bottom-bar img
    {   
        height: 50px;
    }
}
@media(max-width: 688px){
    .bottomBar .inner-bottom-bar{
        grid-template-columns: 1fr;
        line-height: 30px;
        padding: 20px 0;
        row-gap: 10px;
    }
    .App
    {
        padding-bottom: 220px;
    }
    .topbar_red
    {
        font-size: 10px;
        padding: 10px 10px;
        width: calc(100% - 30px);
    }
    .BottomBarRed {
        width: calc(100% - 30px);
    }
}

@media(max-width: 360px){
    .topbar_red>span.nowrap{
        display: none;
    }
}
