.AdminPage .organisationBlock
{
    margin: 20px auto;
    border: 1px solid black;
    width: max-content;
    text-align: center;
    padding: 7px;
    position: relative;
}

.AdminPage .title
{
    cursor: pointer;
    font-weight: bold;
}
.AdminPage .number
{
    position: absolute;
    top: 2px;
    left: -22px;
    opacity: 0.2;
}
.AdminPage .moreinfo
{
    display: block;
}
.AdminPage .linkDiv
{
    margin: 10px auto 0;
    cursor: pointer;
    line-height: 25px;
    width: 120px;
    text-align: center;
    color: white;
    background-color: #9dc22c;
    border-radius: 5px;
    font-weight: normal;
}
.AdminPage .linkDiv:hover
{
    color: blue;
}
.AdminPage .openChangeImage
{
    font-weight: normal;
    color: orange;
    margin-top: 10px;
}
.AdminPage .openChangeImage:hover 
{
    color: red;
    cursor: pointer;
}
.AdminPage .organisationBlock .logos img{
    /* height: 100px; */
    width: 100px;
    margin: 50px 10px 10px;
    min-height: 50px;
    font-weight: normal;
}
.AdminPage .organisationBlock .logos img.whiteBg{
    background-color: white;
}
.AdminPage .organisationBlock .logos img.greyBg{
    background-color: grey;
}
.AdminPage .organisationBlock .logos img:hover {
    cursor: pointer;
    background-color: transparent !important;
    filter: brightness(80%);
    color: blue;
}
.AdminPage .organisationBlock .logos span
{
    font-weight: normal;
    top: 20px;
    position: relative;
    margin: 0 20px;
}
.AdminPage .organisationBlock .logos span:hover
{
    color: blue;
    cursor: pointer;
}
.AdminPage .organisationBlock .logos input
{
    display: none;
}
.AdminPage .toCreate
{
    position: fixed;
    bottom: 30px;
    right: 25px;
    padding: 5px 15px 8px;
    border-radius: 5px;
    display: block;
    color: white;
    background-color: #9dc22c;
}
.AdminPage .toCreate:hover
{
    color: blue;
}
.AdminPage .inputDiv
{
    width: fit-content;
    margin:auto;
    margin-top: 300px;
}