@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  src: url('./ubuntu.woff2') format('woff2');
}

@font-face {
  font-family: 'Source sans pro';
  font-style: normal;
  font-weight: 400;
  src: url('./source-sans-pro.woff2') format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('./roboto.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('./montseratt.woff2') format('woff2');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('./open-sans.woff2') format('woff2');
}