body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f2f2;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box !important;
}

.pt50 {
  padding-top: 50px !important;
}

iframe {
  display: none !important;
}

.thinner {
  max-width: calc(1050px + 15px);
  margin: auto;
  padding: 0 15px;
}

a {
  text-decoration: none;
  color: inherit;
}
.App
{
  min-height: 100vh;
  position: relative;
  padding-bottom: 150px;
  overflow: hidden;
}
.o4
{
  opacity: 0.4 !important;
}
select
{
  height: 25px;
  margin-top: -2px;
}

body:has(.blackBlock) {
   /* height: 100%; */
   overflow-y: hidden;
}

.slide .poweredByViewie
{
    position: fixed;
    bottom: 0;
    right: 0;
    /* remove 20% of poweredbyviewie in demo */
    width: calc(180px * 0.8);
    height: calc(40px * 0.8);
    z-index: 99;
}