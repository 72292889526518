/*************
	STANDARD 
**************/
#vmt-49 .container { max-width: none; width:1920px; height:1080px; padding: 0; overflow: hidden; }
#vmt-49 .overflow { width: 1920px; height: 1080px; overflow: hidden; position: relative; z-index: 1; }
#vmt-49 .nopadding {padding:0;}

/*************
	HOUSEDETAILS 
**************/
#vmt-49 .house0 { width: 1920px; height: 1080px; margin: 0; background-size: cover; background-position: center; }
#vmt-49 .house1 { width: 514px; height: 302px; background-size: cover; background-position: center; }
#vmt-49 .status { position: absolute; font-size: 50px; font-weight: 500; top:0; right:0; bottom:0; left:0; text-transform: uppercase; display: flex; justify-content: center; align-items: center; }
#vmt-49 .price { font-size: 75px; font-weight: 500; }
#vmt-49 .house-block { width: 325px; }
#vmt-49 .street { margin-right: 8px; max-width: 394px; }
#vmt-49 .house-number { margin-right: 8px; max-width: 120px; }
#vmt-49 .city { max-width: 300px; }

/*************
	CUSTOM STYLING
**************/
#vmt-49 .address-details { font-size: 65px; font-weight: bold; }
#vmt-49 .house-block { width: 518px; height: 306px; position: absolute; right: 0; bottom: 0; z-index: 2; padding: 4px 0 0 4px; }
#vmt-49 .status-block { position: absolute; top: 0; right: 0; width: 500px; height: 125px; z-index: 1; overflow: hidden; }
#vmt-49 .address-price-block { position: absolute; bottom: 0; left: 0; display: flex; flex-direction: column; z-index: 2; padding-left: 50px; padding-bottom: 45px; }
#vmt-49 .cborder { width: 60%; height: 3px; margin-top: 15px; margin-bottom: 20px; }
#vmt-49 .bottom-shadow { position: absolute; right: 0; bottom: 0; left: 0; width: 100%; height: 300px; background: linear-gradient(to bottom, transparent 0%, rgba(0,0,0,0.75) 75%); z-index: 1; }

/* BOOTSTRAP 4 */
#vmt-49 .row.no-gutters { margin-right: 0; margin-left: 0; }
#vmt-49 .row.no-gutters > [class^="col-"], #vmt-49 .row.no-gutters > [class*=" col-"] { padding-right: 0; padding-left: 0; }

#vmt-49 .energy
{
    position: absolute;
    bottom: 15px;
    right: 540px;
    z-index: 2;
}


/*************
	STANDARD 
**************/
.portrait #vmt-49 .container { width:1080px; height:1920px; }
.portrait #vmt-49 .overflow { width:1080px; height:1920px; }

/*************
	HOUSEDETAILS 
**************/
.portrait #vmt-49 .house0 { width: 1080px; height: 1920px; }
.portrait #vmt-49 .house1 { width: 314px; height: 180px; }

/*************
	CUSTOM STYLING
**************/
.portrait #vmt-49 .address-price-block { max-width: 745px; }
.portrait #vmt-49 .house-block { width: 318px; height: 184px; position: absolute; right: 0; bottom: 0; z-index: 2; padding: 4px 0 0 4px; }

/* nice 27 december edit for adding energy labels */

