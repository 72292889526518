/*****************
    GLOBAL ELEMENTS
*****************/

#vmts-04 .container {
    max-width: none;
    width: 1920px;
    height: 1080px;
    padding: 0;
    display: flex;
    background-color: #fff;
}

#vmts-04 .container .content-slide {
    height: calc(100% - 80px);
    margin-top: 40px;
    width: calc(100% - 120px);
    margin-left: 80px;
}

#vmts-04 .container .content-slide .greyBox {
    height: 100%;
    background-color: #e6e6e6;
    width: 925px;
    position: relative;
}

#vmts-04 .container .content-slide .greyBox .address {
    line-height: 70px;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    padding: 0 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

#vmts-04 .container .content-slide .greyBox .mainImageContainer {
    width: calc(100% + 80px);
    margin-left: -40px;
    height: 625px;
    background-color: lightgrey;
    overflow: hidden;
    position: relative;

}

#vmts-04 .container .content-slide .greyBox .mainImageContainer .status {
    position: absolute;
    margin-top: 15px;
    top: 65px;
    left: -100px;
    transform: rotate(-47deg);
    padding: 10px 100px;
    width: 410px;
    font-size: 27px;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    z-index: 100;
    white-space: nowrap;
}

#vmts-04 .container .content-slide .greyBox .mainImageContainer .bg-img {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

#vmts-04 .content-slide .greyBox .price {
    text-align: center;

    line-height: 80px;
    width: 620px;
    margin-top: -40px;
    z-index: 40;
    position: relative;
    font-weight: bold;
    font-size: 35px;

    left: 50%;
    background-color: orange;
    transform: translateX(-50%);
}

#vmts-04 .container .content-slide .greyBox .text-container {
    width: 100%;
    bottom: 0;
    position: absolute;
    height: 305px;
    padding-top: 40px;
}

#vmts-04 .content-slide .greyBox .text-container .boxTable {
    height: calc(100% - 96px);
    display: inline-flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 560px;
    margin: 48px 65px;
    margin-right: 0;
}

#vmts-04 .content-slide .greyBox .text-container .boxTable .boxTableItem {
    flex: 0 1 56px;
    font-size: 23px;
    /* font-weight: bold; */
    margin-right: 140px;
    width: 340px;
    white-space: nowrap;
}

#vmts-04 .content-slide .greyBox .text-container .boxTable .boxTableItem span.js-ka-k {
    width: 255px;
    display: inline-flex;

}

#vmts-04 .content-slide .greyBox .text-container .boxTable .overWriteBorderBottom {
    background: darkgrey;
    height: 160px;

    position: absolute;
    bottom: 65px;
    width: 2px;
    left: 461px;

}

#vmts-04 .container .content-slide .sideImagesContainer {
    position: fixed;
    top: 110px;
    right: 40px;
    height: 625px;
    width: 800px;

}

#vmts-04 .container .content-slide .sideImagesContainer .s-img {
    position: absolute;
    background-color: lightgrey;
    width: calc(50% - 10px);
    height: calc(50% - 10px);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

#vmts-04 .container .content-slide .sideImagesContainer .s-img.bottom {
    bottom: 0;
}

#vmts-04 .container .content-slide .sideImagesContainer .s-img.right {
    right: 0;
}

#vmts-04 .logoContainer {
    width: 820px;
    /* line-height: 300px; */
    position: fixed;
    right: 40px;
    bottom: 40px;
    text-align: center;
}

#vmts-04 .logoContainer .logo {
    margin: auto;
    height: 220px;
    max-width: 700px;

}

/* #vmts-04 .poweredByViewie {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 180px;
    height: 40px;
} */


    .portrait #vmts-04 .container {
        width: 1080px;
        height: 1920px;

    }

    .portrait #vmts-04 .container .content-slide .greyBox {
        height: 1015px;
        top: 150px;
    }

    .portrait #vmts-04 .container .content-slide .sideImagesContainer {
        position: relative;
        margin-top: 70px;
        left: -40px;
        height: 635px;
        width: 1005px;

    }

    .portrait #vmts-04 .logoContainer {
        top: 0;
        right: 0;
        bottom: auto;
        line-height: 190px;
        width: 100%;
    }

    .portrait #vmts-04 .logoContainer .logo {
        max-height: 160px;
        height: auto;
        max-width: none;
    }
