/********
    Datum: 13-03-2019
    Notes: Open Huis Changes
********/

/*************
    STANDARD
**************/
#vmt-04 .container {
    max-width: none;
    width: 1920px;
    height: 1080px;
}

#vmt-04 .overflow {
    width: 658px;
    height: 522px;
    z-index: 10;
    overflow: hidden;
}

#vmt-04 .bg-img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

#vmt-04 .ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

/*shit*/
#vmt-04 .extrapadding {
    padding: 30px;
}

/*************
	HOUSEDETAILS
**************/
#vmt-04 .status {
    width: 100%;
    height: 65px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 35px;
    position: absolute;
    display: flex;
    align-items: center;
}

#vmt-04 .house0 {
    width: 658px;
    height: 522px;
    margin: 0;
}

#vmt-04 .house1 {
    width: 213px;
    height: 170px;
    margin: 0px 0px 3px 6px;
}

#vmt-04 .house2 {
    width: 213px;
    height: 170px;
    margin: 3px 0px 3px 6px;
}

#vmt-04 .house3 {
    width: 213px;
    height: 170px;
    margin: 3px 0px 0px 6px;
}

#vmt-04 .firsthouse {
    padding: 30px 20px 0 62px;
}

#vmt-04 .secondhouse {
    padding: 30px 62px 0 20px;
}

#vmt-04 .address-details {
    width: 878px;
    padding: 5px 10px 5px 12px;
    font-size: 32px;
    font-weight: bold;
}

#vmt-04 .street {
    max-width: 520px;
    margin-left: 8px;
}

#vmt-04 .house-number {
    max-width: 120px;
    margin-left: 8px;
}

#vmt-04 .housenumber-addtion {
    max-width: 96px;
    display: inline-block;
}

#vmt-04 .city {
    max-width: 848px;
    margin-left: 8px;
}

#vmt-04 .details {
    font-size: 26px;
    line-height: 2.25;
    padding: 50px 0 0;
    height: 180px;
    /* font-weight: bold; */
    white-space: nowrap;
}

#vmt-04 .price {
    /* text-transform: uppercase; */
    font-size: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    font-weight: bold;
}

/*************
	CUSTOM STYLING
**************/
#vmt-04 .status.sold-wr,
#vmt-04 .status.rented-wr {
    font-size: 30px !important;
}

#vmt-04 .logo {
    /* width: 100%; */
    height: 100%;
}

#vmt-04 .logo img {
    width: 100%;
    height: 100%;
}

#vmt-04 .status-price-bar {
    bottom: -30px;
    left: -10px;
    position: absolute;
    z-index: 100;
}

#vmt-04 .top-status {
    padding: 20px;
    width: 1920px;
    height: 225px;
}

#vmt-04 .bottom-bar {
    height: 23px;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
}

#vmt-04 .border {
    height: 45px;
    width: 4px;
    z-index: 1;
    margin: auto;
}

#vmt-04 .status-block {
    width: 275px;
    height: 65px;
}

#vmt-04 .status-block span {
    width: 275px;
    text-align: center;
}

/*************
    OPENN HOUSE
**************/
#vmt-04 .open-house-wrap {
    display: none;
}

#vmt-04 .open-house-wrap {
    position: absolute;
}

#vmt-04 .open-house-wrap.first-house {
    bottom: 295px;
    left: 50px;
}

#vmt-04 .open-house-wrap.second-house {
    bottom: 295px;
    left: 970px;
}

#vmt-04 .open-house-wrap .open-house-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    line-height: 1.2;
    letter-spacing: 1px;
    backgroundColor: #ff4729;
    color: white;
}

#vmt-04 .open-house-wrap .oh-title {
    font-size: 1.8rem;
    font-weight: 600;
    text-transform: uppercase;
}

#vmt-04 .open-house-wrap .oh-date {
    font-size: 1.4rem;
    font-weight: 300;
}

#vmt-04 .open-house-wrap .oh-time {
    font-size: 1.25rem;
    font-weight: 300;
}

/* #vmt-04 .poweredByViewie {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 180px;
    height: 40px;
} */



    /************  STANDARD
    **************/
    .portrait #vmt-04 .container {
        width: 1080px;
        height: 1920px;
    }

    .portrait #vmt-04 .overflow {
        width: 762px;
        height: 522px;
    }

    /************  HOUSEDETAILS
    **************/
    .portrait #vmt-04 .firsthouse,
    .portrait #vmt-04 .secondhouse {
        width: 1080px;
        padding: 30px 30px 0px 30px;
    }

    .portrait #vmt-04 .house0 {
        width: 762px;
        height: 522px;
    }

    .portrait #vmt-04 .house1 {
        width: 249px;
    }

    .portrait #vmt-04 .house2 {
        width: 249px;
    }

    .portrait #vmt-04 .house3 {
        width: 249px;
    }

    .portrait #vmt-04 .street {
        max-width: 862px;
    }

    .portrait #vmt-04 .city {
        max-width: 990px;
    }

    #vmt-04 .housenumber {
        margin-left: 5px;
    }

    .portrait #vmt-04 .details {
        padding: 41px 0;
        height: 184px;
    }

    .portrait #vmt-04 .address-details {
        width: 1020px;
    }

    /************  CUSTOM
    **************/
    .portrait #vmt-04 .top-status {
        text-align: center;
        padding: 30px;
        width: 1080px;
        height: 260px;
    }

    .portrait #vmt-04 .bottom-bar {
        height: 27px;
    }

    .portrait #vmt-04 .status-price-bar {
        width: 565px;
    }

    /************  OPENN HOUSE
    **************/
    .portrait #vmt-04 .open-house-wrap.first-house {
        top: 685px;
        left: 20px;
    }

    .portrait #vmt-04 .open-house-wrap.second-house {
        bottom: 310px;
        left: 20px;
    }
    /* added by Menno for demo */
    .portrait #vmt-04 .firsthouse,
    .portrait #vmt-04 .secondhouse
    {
        flex: 0 0 100%;
        max-width: 100%;
    }
