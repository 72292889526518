/**** Versie: 09-01-2019 ****/

/*************
    STANDARD 
**************/

#vmt-09 .container {
    max-width: none;
    width:1920px;
    height:1080px;
 
}

#vmt-09 .topbar {
 
    height: 170px;
    width: 100%;
    position: absolute;
}
#vmt-09 .img-container
{
    width: 1370px;
    margin-left: 500px;
    position: relative;
    z-index: 1;
}
#vmt-09 .bottom-pictures
{
    display: none;
}
#vmt-09 .bg-img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 780px;
    border-radius: 12px;
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.4);
}
#vmt-09 .ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    /* white-space: nowrap; */
}

/*************
    HOUSEDETAILS 
**************/


#vmt-09 .logo {
    padding: 30px;
    height: 100%;
    width: 500px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

#vmt-09 .bottombar {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 240px;
}

#vmt-09 .address-details {
    width: 100%;
    min-height: 130px;
    font-size: 49px;
    padding: 25px 0;
    font-weight: bold;
}

#vmt-09 .street {
    float: right;
    max-width: 700px;
}

#vmt-09 .city {
    float: left;
    max-width: 450px;
}

#vmt-09 .housenumber {
    float: right;
    margin-left: 10px;
    max-width: 200px
}


/*************
    CUSTOM STYLING
**************/

#vmt-09 .logo img {
    width: 100%;
    height: auto;
    max-height: 100%;
    object-fit: contain;
}



#vmt-09 .status {
    display: inline-block;
    padding: 0 50px;
    line-height: 85px;
    text-transform: uppercase;
    font-size: 40px;
    border-radius: 12px;
    font-size: 35px;
   
    margin-top: 35px;
    margin-left: 90px;
    font-weight: bold;
}
#vmt-09 .price-container
{
    display: inline-block;
    line-height: 85px;
    padding: 0 110px;
    font-size: 40px;
    position: absolute;
    bottom: 40px;
    right: 45px;
    border-radius: 12px;
    z-index: 1;
}


/* #vmt-09 .poweredByViewie
{
    position: fixed;
    bottom: 0;
    right: 0;
    width: 180px;
    height: 40px;
} */


#vmt-09 .boxTable {
    height: 670px;
    padding-top: 110px;
    padding-left: 35px;
    position: absolute;
   

    display: inline-flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 500px;
 

    position: absolute;
    bottom: 240px;
    font-size: 30px;
}
#vmt-09 .boxTable .boxTableItem {
    flex: 0 1 30px;
    line-height: 70px;
    margin-right: 30px;
}
#vmt-09 .boxTable .boxTableItem span.js-ka-k
{
    width: 230px;
    display: inline-block;
}






    /*************
        STANDARD 
    **************/
    .portrait #vmt-09 .container {
        width: 1080px;
        height: 1920px;
    }
    .portrait #vmt-09 .status
    {
        margin-left: 50%;
        transform:translateX(-50%);
        margin-top: 60px;
    }
    .portrait #vmt-09 .img-container
    {
        margin-left: 40px;
        width: 1000px;
        margin-top: 160px;
        position: absolute;
    }
    .portrait #vmt-09 .img-container .bg-img
    {
        height: 650px;
    }
    .portrait #vmt-09 .address-details
    {
        padding: 0;
        padding-bottom: 10px;
        text-align: center;
    }
    .portrait #vmt-09 .bottom-pictures
    {
        display: grid;
        height: 330px;
        grid-template-columns: 1fr 1fr;
        width: 1000px;
       
        column-gap: 40px;
        margin-top: 40px;
    }

    .portrait #vmt-09 .topbar {
        height: 355px;
    }
    .portrait #vmt-09 .bottom-pictures .s-img
    {   
        height: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 12px;
        box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.4);
    }
    .portrait #vmt-09 .boxTable {
        width: 1000px;
        padding-right: 35px;
        height: 335px;
        bottom: 345px;
        margin-left: 100px
    }   
    .portrait #vmt-09 .price-container {
        right: 50%;
        transform: translateX(50%);
        width: fit-content;
        bottom: 225px;
        white-space: nowrap;
    }
    .portrait #vmt-09 .logo
    {
        margin: auto;
        
        padding-top: 55px;
    }
    .portrait #vmt-09 .bottombar
    {
        height: 270px;
    }
