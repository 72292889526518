/*****************
    GLOBAL ELEMENTS
*****************/

#vmt-10 .container {
    max-width: none;
    width: 1920px;
    height: 1080px;
    padding: 0;
    background-color: #fff;

    /* for demo only */
    display: inline-block;
}

#vmt-10 .container .content-slide {
    margin-top: 60px;
    width: calc(100% - 120px);
    margin-left: 40px;
    height: calc(100% - 60px);
}

#vmt-10 .container .content-slide .house {
    height: 100%;
    width: 950px;
    position: relative;
}

#vmt-10 .container .content-slide .house .address {
    line-height: 80px;
    font-size: 35px;
    text-align: center;
    padding: 0 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 875px;
    position: relative;
    z-index: 1;
    margin: auto;
    font-weight: bold;
}

#vmt-10 .container .content-slide .house .mainImageContainer {
    width: 100%;
    height: 650px;
    background-color: lightgrey;
    position: relative;
    top: -40px;
}

#vmt-10 .container .content-slide .house .mainImageContainer .status {
    position: absolute;
    top: 70px;
    padding: 0 30px;
    left: -20px;
    line-height: 70px;
    font-size: 30px;
    font-weight: bold;
    text-transform: uppercase;
}

#vmt-10 .container .content-slide .house .mainImageContainer .bg-img {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

#vmt-10 .content-slide .house .price {
    text-align: center;

    line-height: 80px;
    width: 620px;
    margin-top: -40px;
    z-index: 40;
    position: relative;
    /* font-weight: bold; */
    font-size: 35px;

    left: 50%;
    background-color: orange;
    transform: translateX(-50%);
}

#vmt-10 .container .content-slide .house .text-container {
    width: 100%;
    bottom: 0;
    position: absolute;
    height: 270px;
}

#vmt-10 .content-slide .house .text-container .boxTable {
    height: calc(100% - 70px);
    display: inline-flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    margin: 48px 30px;
    margin-right: 0;
}

#vmt-10 .content-slide .house .text-container .boxTable .boxTableItem {
    flex: 0 1 60px;
    font-size: 29px;
    /* padding-right: 140px; */
    /* width: 420px; */
    white-space: nowrap;

}

#vmt-10 .content-slide .house .text-container .boxTable .boxTableItem span.js-ka-k {
    width: 280px;
    display: inline-flex;
    margin-right: 20px;

    /* Deze 2 hieronder gelden alleen voor de gene op de rechter rij (na de 3) */
    /* position: relative; */
    /* left: -20px; */
}

/* eerste 3  werkte helaas niett
#vmt-10 .content-slide .house .text-container .boxTable .boxTableItem:nth-child(-n+3) span {
    left: 0;
}
*/
#vmt-10 .content-slide .house .text-container .boxTable .overWriteBorderBottom {
    background: darkgrey;
    height: 190px;
    position: absolute;
    bottom: 40px;
    width: 4px;
    left: 460px;
}

#vmt-10 .container .content-slide .sideImagesContainer {
    position: fixed;
    top: 100px;
    right: 40px;
    height: 650px;
    width: 870px;
}

#vmt-10 .container .content-slide .sideImagesContainer .s-img {
    position: absolute;
    background-color: lightgrey;
    width: calc(50% - 10px);
    height: calc(50% - 10px);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

#vmt-10 .container .content-slide .sideImagesContainer .s-img.bottom {
    bottom: 0;
}

#vmt-10 .container .content-slide .sideImagesContainer .s-img.right {
    right: 0;
}

#vmt-10 .logoContainer {
    width: 870px;
    line-height: 250px;
    position: fixed;
    right: 40px;
    bottom: 20px;
    text-align: center;
}

#vmt-10 .logoContainer .logo {
    margin: auto;
    height: 220px;
    max-width: 700px;

}
/* 
#vmt-10 .poweredByViewie {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 180px;
    height: 40px;
}
 */



    .portrait #vmt-10 .container {
        width: 1080px;
        height: 1920px;

    }

    .portrait #vmt-10 .container .content-slide {
        margin: auto 35px;
        width: calc(100% - 70px);
    }

    .portrait #vmt-10 .container .content-slide .house {
        height: 1625px;
        top: 295px;
        width: 100%;

    }

    .portrait #vmt-10 .container .content-slide .house .mainImageContainer {
        height: 585px;
    }

    .portrait #vmt-10 .container .content-slide .sideImagesContainer {
        position: absolute;
        top: 1000px;
        right: 35px;
        height: 600px;
        width: calc(100% - 70px);

    }

    .portrait #vmt-10 .container .content-slide .house .text-container {
        border-top: 5px solid;
        height: 275px;
    }

    .portrait #vmt-10 .logoContainer {
        top: 0;
        right: 0;
        bottom: auto;
        line-height: 200px;
        width: 100%;
        margin-top: 50px;
    }

    .portrait #vmt-10 .logoContainer .logo {
        max-height: 160px;
        height: auto;
        max-width: 900px;

    }
    .portrait #vmt-10 .content-slide .house .text-container .boxTable .overWriteBorderBottom {
        display: none;
    }