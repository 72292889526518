/********
    Datum: 13-03-2019
    Notes: Open Huis Changes
********/

/*************
    STANDARD
**************/
#vmt-00 .container {
    width: 1920px;
    height: 1080px;
    max-width: none;
    overflow: hidden;
}

#vmt-00 .overflow {
    width: 960px;
    height: 640px;
    z-index: 1;
    overflow: hidden;
}

#vmt-00 .bg-img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block;
}

#vmt-00 .ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

/*************
    HOUSEDETAILS
**************/
#vmt-00 .house1 {
    width: 960px;
    height: 650px;
    margin: 10px 10px 0px 10px;
}

#vmt-00 .house2,
#vmt-00 .house3,
#vmt-00 .house4,
#vmt-00 .house5,
#vmt-00 .house6 {
    height: 320px;
    width: 460px;
    margin: 0px;
}

#vmt-00 .house2,
#vmt-00 .house3 {
    margin-top: 10px;
    margin-bottom: 10px;
}

#vmt-00 .house2,
#vmt-00 .house4 {
    margin-right: 10px;
}

#vmt-00 .house6 {
    right: 10px;
    top: 670px;
    position: absolute;
}

#vmt-00 .status {
    position: absolute;
    margin-top: 15px;
    top: 65px;
    left: -100px;
    transform: rotate(-47deg);
    padding: 10px 100px;
    width: 410px;
    font-size: 27px;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    z-index: 100;
}

#vmt-00 .status.negotiation {
    left: -95px;
    width: 415px;
}

#vmt-00 .logo {
    width: 250px;
    height: 60px;
    position: absolute;
    bottom: 5px;
    left: 100px;
}

#vmt-00 .logo img {
    width: auto;
    height: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
}

/*************
    ADDRESS DETAILS
**************/
#vmt-00 .address-details {
    margin: 30px 0 00px 100px;
    display: table;
    padding-bottom: 10px;
    border-bottom: 3px solid;
    width: 90%;
    font-size: 34px;
    font-weight: 300;
    /* letter-spacing: 2px; */
}

#vmt-00 .street {
    max-width: 640px;
    margin-right: 10px;
    font-size: 34px;
    font-weight: bold;
}

#vmt-00 .house-number {
    max-width: 120px;
    margin-right: 10px;
    font-size: 34px;
    font-weight: bold;
}

#vmt-00 .city {
    max-width: 550px;
    font-size: 34px;
    font-weight: bold;
}

#vmt-00 .details {
    padding: 20px 0 0 100px;
    display: table;
    width: 100%;
    font-size: 24px;
    font-weight: 300;
    /* letter-spacing: 2px; */
    line-height: 50px;
}

#vmt-00 .price {
    max-width: 1290px;
    font-size: 34px;
}

/*************
    CUSTOM STYLING
**************/
#vmt-00 .footer {
    width: 1920px;
    height: 100px;
    position: absolute;
    bottom: 0;
    left: 0;
}

#vmt-00 .footer .leftFooterPart,
#vmt-00 .footer .rightFooterPart {
    position: fixed;
    bottom: 0;
    height: 80px;
}

#vmt-00 .footer .leftFooterPart {
    width: 75%;
    background: red;
}

#vmt-00 .footer .rightFooterPart {
    width: 25%;
    right: 0;
    background: purple;
}

#vmt-00 .footer .rightFooterPart .rightFooterPartSide {
    height: 115px;
    width: 115px;
    position: relative;
    left: -57px;
    top: 24px;
    background: purple;

    transform: rotate(45deg);
}

#vmt-00 .left-block {
    width: 1227px;
    height: 83px;
    bottom: 0;
    position: absolute;
    left: 0;
    float: left;
}

#vmt-00 .right-block {
    width: 1331px;
    height: 103px;
    position: absolute;
    bottom: 0;
    right: 0;
}

#vmt-00 .gmaps {
    width: 100%;
    height: auto;
    overflow: hidden;
}

/*************
    OPENN HOUSE
**************/
#vmt-00 .open-house-wrap {
    display: none;
}

#vmt-00 .open-house-wrap {
    position: absolute;
    bottom: 425px;
    left: 260px;
}

#vmt-00 .open-house-wrap .open-house-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    line-height: 1.2;
    letter-spacing: 1px;
    background-color: #ff4729;
    color: white;
}

#vmt-00 .open-house-wrap .oh-title {
    font-size: 2.25rem;
    font-weight: 600;
    text-transform: uppercase;
}

#vmt-00 .open-house-wrap .oh-date {
    font-size: 2.1rem;
    font-weight: 300;
}

#vmt-00 .open-house-wrap .oh-time {
    font-size: 1.6rem;
    font-weight: 300;
}




    /************  STANDARD
    **************/
    .portrait #vmt-00 .container {
        width: 1080px;
        height: 1920px;
    }

    .portrait #vmt-00 .overflow {
        width: 1080px;
        height: 620px;
    }

    /************  HOUSEDETAILS
    **************/
    .portrait #vmt-00 .house1 {
        width: 1040px;
        height: 640px;
        margin: 20px;
        margin-bottom: 0;
    }

    .portrait #vmt-00 .house2,
    .portrait #vmt-00 .house3,
    .portrait #vmt-00 .house4,
    .portrait #vmt-00 .house5 {
        height: 380px;
        width: 510px;
        margin: 0px;
    }

    .portrait #vmt-00 .house2,
    .portrait #vmt-00 .house4 {
        margin: 0 20px;
    }

    .portrait #vmt-00 .house2,
    .portrait #vmt-00 .house3 {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .portrait #vmt-00 .house6 {
        display: none;
    }

    .portrait #vmt-00 .col-12 {
        margin: auto;
        max-width: 96%;
    }

    /************  ADDRESS DETAILS
    **************/
    .portrait #vmt-00 .address-details {
        margin: 35px 0 0 30px;
        width: 96%;
        padding-bottom: 35px;
    }

    .portrait #vmt-00 .details {
        padding: 20px 0 0 30px;
    }

    /************  CUSTOM STYLING
    **************/
    .portrait #vmt-00 .gmaps-block {
        width: 100%;
        height: 220px;
        overflow: hidden;
    }

    .portrait #vmt-00 .col-md-3.col-middle {
        width: 100%;
    }

    .portrait #vmt-00 .col-md-9.col-middle {
        width: 100%;
    }

    .portrait #vmt-00 .col-md-6.col-left {
        width: 100%;
    }

    .portrait #vmt-00 .col-md-6.col-right {
        width: 100%;
    }

    .portrait #vmt-00 .footer {
        width: 1080px;
    }

    /************  OPENN HOUSE
    **************/
    .portrait #vmt-00 .open-house-wrap {
        top: 540px;
        right: 0;
        bottom: auto;
        left: 0;
    }

    .portrait #vmt-00 .open-house-wrap .open-house-inner {
        width: 50%;
        margin: auto;
    }
