/********
    Datum: 14-03-2019
    Notes: Open Huis Changes
********/

/*************
    STANDARD
**************/
#vmt-13 .container {
    max-width: none;
    width: 1920px;
    height: 1080px;
}

#vmt-13 .overflow {
    width: 1043.33px;
    height: 800px;
    z-index: 10;
    overflow: hidden;
}

#vmt-13 .bg-img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}



#vmt-13 .extrapadding {
    padding: 30px;
}

#vmt-13 .container {
    padding: 40px 55px;
}

/*************
    HOUSEDETAILS
**************/
#vmt-13 .house0 {
    width: 1043.33px;
    height: 800px;
}

#vmt-13 .house1 {
    height: 220px;
}

#vmt-13 .house2 {
    height: 220px;
}

#vmt-13 .house3 {
    height: 220px;
}

#vmt-13 .house4 {
    height: 220px;
}

#vmt-13 .address-details {
    padding: 10px 30px;
    font-size: 44px;
    font-weight: bold;
    line-height: 80px;
    height: 100px;
}

#vmt-13 .address-details .comma {
    display: none;
}

#vmt-13 .address-details>.ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    max-height: 90px;
}

#vmt-13 .city.ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 330px;
}

#vmt-13 .street {
    max-width: 530px;
}

#vmt-13 .house-number {
    max-width: 125px;
    margin-left: 8px;
}

#vmt-13 .city {
    float: right;
    text-align: right;
}

#vmt-13 .details {
    font-size: 30px;
    height: 300px;
    /* font-weight: bold; */
    line-height: 1.8;
}

#vmt-13 .status {
    z-index: 1000;
    padding: 15px 20px;
    position: absolute;
    font-size: 32px;
    margin-top: 20px;
    font-weight: bold;
    text-transform: uppercase;
}

/*************
    CUSTOM
**************/
#vmt-13 .smallpadding {
    padding: 5px;
    margin-bottom: 30px;
}

#vmt-13 .images .col-6:nth-child(odd) .smallpadding {
    margin-right: 15px;
}

#vmt-13 .images .col-6:nth-child(even) .smallpadding {
    margin-left: 15px;
}

#vmt-13 .borderbottom {
    height: 4px;
}

#vmt-13 .price-block {
    font-size: 50px;
    font-weight: bold;
    padding: 15px 0px;
}

#vmt-13 .details .row .col-6:last-child {
    padding-left: 15px;
}

#vmt-13 .logo {
    width: 736.66px;
    height: 220px;
    padding: 30px;
}

#vmt-13 .logo img {
    width: auto;
    height: 100%;
    max-width: 100%;
    max-height: 160px;
}

/*************
    OPENN HOUSE
**************/
#vmt-13 .open-house-wrap {
    display: none;
}

#vmt-13 .open-house-wrap {
    position: absolute;
    bottom: 160px;
    left: 55px;
}

#vmt-13 .open-house-wrap .open-house-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    line-height: 1.2;
    letter-spacing: 1px;
    background-color: #ff4729;
    color: white;
}

#vmt-13 .open-house-wrap .oh-title {
    font-size: 1.8rem;
    font-weight: 600;
    text-transform: uppercase;
}

#vmt-13 .open-house-wrap .oh-date {
    font-size: 1.4rem;
    font-weight: 300;
}

#vmt-13 .open-house-wrap .oh-time {
    font-size: 1.25rem;
    font-weight: 300;
}

/* #vmt-13 .poweredByViewie {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 180px;
    height: 40px;
} */

/* @media (min-width: 2160px) {
    #vmt-13 .container {
        zoom: 2;
    }
} */


    /************  STANDARD
    **************/
    .portrait #vmt-13 .container {
        width: 1080px;
        height: 1920px;
        padding: 30px 40px;
    }

    .portrait #vmt-13 .overflow {
        width: 1000px;
        height: 550px;
    }

    /************  HOUSEDETAILS
    **************/
    .portrait #vmt-13 .house0 {
        width: 1000px;
        height: 550px;
    }

    .portrait #vmt-13 .address-details .comma {
        display: inline-block;
    }

    .portrait #vmt-13 .address-details>.ellipsis {

        text-align: center;

    }

    .portrait #vmt-13 .address-details .city.ellipsis {
        text-overflow: initial;
        float: initial;
        text-align: left;
        max-width: none;
    }

    .portrait #vmt-13 .house1 {
        height: 294px;
    }

    .portrait #vmt-13 .house2 {
        height: 294px;
    }

    .portrait #vmt-13 .house3 {
        height: 294px;
    }

    .portrait #vmt-13 .house4 {
        height: 294px;
    }

    .portrait #vmt-13 .details {
        font-size: 28px;
        height: 180px;
        line-height: 1.8;
    }

    /* #vmt-13 .details .property-area {
        font-size: 27.5px;
        margin-top: 2px;
    } */

    .portrait #vmt-13 .price-block {
        font-size: 50px;
        padding: 10px;
        margin-bottom: 25px;
    }

    .portrait #vmt-13 .logo {
        width: 1000px;
        height: 250px;
    }

    .portrait #vmt-13 .logo img {
        max-height: 240px;
    }

    /************  CUSTOM
    **************/
    .portrait #vmt-13 .details .row:nth-child(even) .col-6 {
        padding-left: 15px !important;
    }

    .portrait #vmt-13 .details .row .col-6:last-child {
        padding-left: 0;
    }

    .portrait #vmt-13 .details .row {
        width: 50%;
        float: left;
    }

    /************  OPENN HOUSE
    **************/
    .portrait #vmt-13 .open-house-wrap {
        bottom: 1260px;
        left: 40px;
    }
/* added for demo */
    .portrait #vmt-13 .col-left,
    .portrait #vmt-13 .col-right
    {
        flex: 0 0 100%;
        max-width: 100%;
    }
    
    .landscape #vmt-13 .col-xl-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    