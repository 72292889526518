/********
    Datum: 14-03-2019
    Notes: Open Huis Changes
********/

/*************
    STANDARD
**************/
#vmt-05 .container {
    max-width: none;
    width: 1920px;
    height: 1080px;
}

#vmt-05 .overflow {
    width: 1060px;
    height: 694px;
    z-index: 10;
    overflow: hidden;
}

#vmt-05 .bg-img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

#vmt-05 .ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

#vmt-05 .extrapadding {
    padding: 0 30px;
}

/*************
	HOUSEDETAILS
**************/
#vmt-05 .status {
    position: absolute;
    z-index: 1000;
    padding: 15px 30px;
    font-size: 40px;
    right: 0;
    top: 15px;
    font-weight: bold;
    text-transform: uppercase;
}

#vmt-05 .house0 {
    width: 1060px;
    height: 694px;
}

#vmt-05 .house1 {
    width: 347px;
    height: 205px;
    margin: 37px 4px 34px 0;
}

#vmt-05 .house2 {
    width: 347px;
    height: 205px;
    margin: 37px 2px 34px 2px;
}

#vmt-05 .house3 {
    width: 347px;
    height: 205px;
    margin: 37px 0 34px 4px;
}

#vmt-05 .address-details {
    font-size: 40px;
}

#vmt-05 .street {
    max-width: 574px;
    padding: 30px 0 20px 0;
    font-weight: bold;
}

#vmt-05 .house-number {
    max-width: 150px;
    margin-left: 8px;
    padding: 30px 0 20px 0;
    font-weight: bold;
}

#vmt-05 .city {
    /* font-weight: bold; */
    max-width: 732px;
    padding-bottom: 30px;
    flex: 0 1 100%;
}

#vmt-05 .details {
    font-size: 20px;
    /* font-weight: bold; */
    line-height: 2.9;
    padding: 12px 0px;
    height: 200px;
}

#vmt-05 .details .col-5 {
    line-height: 1;
    display: flex;
    align-items: center;
    font-weight: bold;
}

#vmt-05 .price {
    font-size: 50px;
    font-weight: bold;
    padding: 0 0;
}

#vmt-05 .description {
    font-size: 26px;
    line-height: 1.6;
    padding: 5% 0%;
    height: 334px;
}

#vmt-05 .description p {
    overflow: hidden;
    /* max-height: 250px; */
}

#vmt-05 .logo {
    position: absolute;
    width: auto;
    z-index: 100;
    height: 145px;
    left: 50px;
    background-color: #fff;
}

/*************
	CUSTOM STYLING
**************/
#vmt-05 .logo img {
    width: auto;
    height: calc(145px - 50px);
    margin: 25px;
    max-width: 500px;
}

#vmt-05 .ask-price {
    font-size: 40px;
    padding: 35px 0 0 0;
    text-transform: uppercase;
}

#vmt-05 .orange {
    width: 100px;
    height: 100%;
}

#vmt-05 .website {
    font-size: 42px;
    line-height: 2.5;
}

#vmt-05 .footer {
    width: 100%;
    height: 110px;
}

#vmt-05 .border {
    height: 2px;
}

/*************
    OPENN HOUSE
**************/
#vmt-05 .open-house-wrap {
    display: none;
}

#vmt-05 .open-house-wrap {
    position: absolute;
    bottom: 405px;
    left: 23px;
    z-index: 999;
}

#vmt-05 .open-house-wrap .open-house-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    line-height: 1.2;
    letter-spacing: 1px;
    background-color: #ff4729;
    color: white;
}

#vmt-05 .open-house-wrap .oh-title {
    font-size: 1.8rem;
    font-weight: 600;
    text-transform: uppercase;
}

#vmt-05 .open-house-wrap .oh-date {
    font-size: 1.4rem;
    font-weight: 300;
}

#vmt-05 .open-house-wrap .oh-time {
    font-size: 1.25rem;
    font-weight: 300;
}

/* #vmt-05 .poweredByViewie {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 180px;
    height: 40px;
} */

#vmt-05 .fadebottom {
    -webkit-mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
    mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
}

@media (min-width: 2160px) {
    #vmt-05 .container {
        zoom: 2;
    }
}



    /************  STANDARD
    **************/
    .portrait #vmt-05 .container {
        width: 1080px;
        height: 1920px;
        overflow: hidden;
    }

    .portrait #vmt-05 .extrapadding {
        padding: 0 40px;
    }

    .portrait #vmt-05 .extrapadding.col-7 {
        max-width: none;
    }

    .portrait #vmt-05 .extrapadding.col-5 {
        flex: none;
        max-width: none;
    }

    /************  HOUSEDETAILS
    **************/
    .portrait #vmt-05 .overflow {
        width: 1000px;
        height: 620px;
    }

    .portrait #vmt-05 .house0 {
        width: 1000px;
        height: 620px;
    }

    .portrait #vmt-05 .house1 {
        height: 210px;
        width: 324px;
        margin: 12px 4px 12px 0;
    }

    .portrait #vmt-05 .house2 {
        height: 210px;
        width: 324px;
        margin: 12px 2px 12px 4px;
    }

    .portrait #vmt-05 .house3 {
        height: 210px;
        width: 324px;
        margin: 12px 0px 12px 8px;
    }

    .portrait #vmt-05 .address {
        line-height: 54px;
        height: 74px;
        font-size: 44px;
        padding-top: 10px;
    }

    .portrait #vmt-05 .city {
        max-width: 1000px;
        line-height: 64px;
        height: 94px;
        font-size: 44px;
        padding-bottom: 10px;
    }

    .portrait #vmt-05 .street {
        max-width: 800px;
    }

    .portrait #vmt-05 .details {
        height: 255px;
        font-size: 27px;
        line-height: 2.4;
        padding-top: 30px;
    }

    .portrait #vmt-05 .price {
        font-size: 65px;
        height: 120px;
    }

    .portrait #vmt-05 .description {
        height: 275px;
        font-size: 27px;
        padding: 53px 0;
    }

    .portrait #vmt-05 .description p {
        /* max-height: 170px; */
        margin: 0;
    }

    .portrait #vmt-05 .logo {
        width: auto;
        height: 125px;
        left: 70px;
    }

    .portrait #vmt-05 .logo img {
        height: calc(125px - 40px);
        margin: 20px;
    }

    /************  CUSTOM STYLING
    **************/
    .portrait #vmt-05 .ask-price {
        padding-top: 30px;
        height: 90px;
    }

    .portrait #vmt-05 .website {
        padding: 0 140px;
        line-height: 2.6;
    }

    .portrait #vmt-05 .text-white {
        margin-left: 0px;
    }

    .portrait #vmt-05 .footer {
        width: 100%;
        height: 110px;
        bottom: 0;
        position: absolute;
    }

    /************  OPENN HOUSE
    **************/
    .portrait #vmt-05 .open-house-wrap {
        top: 465px;
        left: 25px;
        bottom: auto;
    }
