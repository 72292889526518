/********
    Datum: 13-03-2019
    Notes: Open Huis Changes
********/

/*************
    STANDARD
**************/
#vmt-15 .container {
    max-width: none;
    width: 1920px;
    height: 1080px;
}

#vmt-15 .overflow {
    width: 937px;
    height: 660px;
    margin-bottom: 4px;
    z-index: 10;
    overflow: hidden;
}

#vmt-15 .bg-img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

#vmt-15 .ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

#vmt-15 .extrapadding {
    padding: 3%;
}

#vmt-15 .container {
    padding: 15px;
}

/*************
    HOUSEDETAILS
**************/
#vmt-15 .status {
    /* font-weight: bold; */
    position: absolute;
    padding: 1% 5%;
    font-size: 60px;
    text-transform: uppercase;
    top: 0;
    z-index: 100;
}

#vmt-15 .price-block {
    font-size: 36px;
}

#vmt-15 .details {
    float: left;
    font-size: 26px;
    line-height: 2.1;
    font-weight: 300;
    height: 380px;
    margin-right: 2px;
    padding: 2% 3% 0% 3%;
    width: 468px;
}

#vmt-15 .house1 {
    width: 937px;
    height: 660px;
}

#vmt-15 .house2 {
    width: 468px;
    height: 330px;
    float: left;
    margin-right: 2px;
    margin-bottom: 4px;
}

#vmt-15 .house3 {
    width: 468px;
    height: 330px;
    float: right;
    margin-left: 2px;
    margin-bottom: 4px;
}

#vmt-15 .house4 {
    width: 468px;
    height: 326px;
    float: left;
    margin-right: 2px;
    margin-bottom: 4px;
}

#vmt-15 .house5 {
    width: 468px;
    height: 326px;
    float: right;
    margin-left: 2px;
    margin-bottom: 4px;
}

#vmt-15 .gmaps-block {
    width: 468px;
    float: right;
    margin-left: 2px;
    height: 380px;
    overflow: hidden;
}

#vmt-15 .gmaps {
    width: 100%;
    height: 380px;
}

#vmt-15 .address-price-block {
    width: 100%;
    height: 380px;
    font-size: 40px;
    letter-spacing: 2px;
    position: relative;
}

#vmt-15 .address-price-block .row {
    padding: 15px 60px 10px;
    height: 50%;
}

#vmt-15 .address-details {
    font-size: 2.2rem;
    /* font-weight: bold; */
}

#vmt-15 .logo {
    width: auto;
    height: 120px;
    max-width: 380px;
}

#vmt-15 .city {
    width: 100%;
    max-width: 745px;
}

#vmt-15 .street {
    max-width: 587px;
    font-weight: bold;
}

#vmt-15 .house-number {
    max-width: 150px;
    margin-left: 8px;
}

/*************
    CUSTOM STYLING
**************/
#vmt-15 .arrow-right {
    width: 0;
    height: 0;
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
    border-left: 30px solid;
    position: absolute;
    top: 50%;
    left: -60px;
    transform: translateY(-50%);
}

#vmt-15 .marker {
    width: 100%;
    height: 100%;
}

#vmt-15 svg#marker {
    width: 100%;
    height: auto;
    padding-top: 15px;
    padding: 10px;
}

#vmt-15 .price-logo {
    width: 100%;
    height: auto;
}

#vmt-15 .ask-price {
    font-size: 32px;
}

#vmt-15 .col-left {
    padding-right: 0px;
    padding-right: 4px;
}

#vmt-15 .col-right {
    padding-left: 0px;
}

#vmt-15 .header {
    font-size: 40px;
    line-height: 1.3;
}

#vmt-15 .bordertop {
    position: absolute;
    left: 60px;
    right: 60px;
    height: 2px;
}

#vmt-15 .middleborder {
    position: absolute;
    top: 35px;
    right: 0;
    bottom: 35px;
    left: 0;
    width: 2px;
    margin: 0 auto;
}

/*************
    OPENN HOUSE
**************/
#vmt-15 .open-house-wrap {
    display: none;
}

#vmt-15 .open-house-wrap {
    position: absolute;
    bottom: 425px;
    left: 15px;
}

#vmt-15 .open-house-wrap .open-house-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    line-height: 1.2;
    letter-spacing: 1px;
    background-color: #ff4729;
    color: white;
}

#vmt-15 .open-house-wrap .oh-title {
    font-size: 1.8rem;
    font-weight: 600;
    text-transform: uppercase;
}

#vmt-15 .open-house-wrap .oh-date {
    font-size: 1.4rem;
    font-weight: 300;
}

#vmt-15 .open-house-wrap .oh-time {
    font-size: 1.25rem;
    font-weight: 300;
}

/* #vmt-15 .poweredByViewie {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 180px;
    height: 40px;
} */



    /************  STANDARD
    **************/
    /* .portrait #vmt-15 .col-6 {
        flex: none;
        max-width: none;
    } */

    .portrait #vmt-15 .container {
        width: 1080px;
        height: 1920px;
        padding: 10px;
        overflow: hidden;
    }

    .portrait #vmt-15 .overflow {
        width: 1058px;
        height: 600px;
    }

    /************  HOUSEDETAILS
    **************/
    .portrait #vmt-15 .house1 {
        width: 1058px;
        height: 600px;
    }

    .portrait #vmt-15 .house2 {
        width: 527px;
        height: 320px;
        margin-top: 4px;
    }

    .portrait #vmt-15 .house3 {
        width: 527px;
        height: 320px;
        margin-top: 4px;
    }

    .portrait #vmt-15 .house4 {
        width: 527px;
    }

    .portrait #vmt-15 .house5 {
        width: 527px;
    }

    .portrait #vmt-15 .details {
        width: 527px;
    }

    .portrait #vmt-15 .gmaps-block {
        width: 527px;
        height: 337px;
    }

    .portrait #vmt-15 .price {
        font-size: 40px;
    }

    .portrait #vmt-15 .details {
        line-height: 1.9;
        height: 337px;
        padding: 20px;
    }

    .portrait #vmt-15 .address-details {
        height: 120px;
        padding-top: 2%;
        font-size: 34px;
    }

    .portrait #vmt-15 .gmaps {
        width: 100%;
        height: auto;
    }

    .portrait #vmt-15 .city {
        max-width: 850px;
    }

    .portrait #vmt-15 .street {
        max-width: 620px;
    }

    .portrait #vmt-15 .house-number {
        max-width: 180px;
    }

    /************  CUSTOM STYLING
    **************/
    .portrait #vmt-15 .col-left {
        width: 100%;
        height: 900px;
        overflow: hidden;
        padding-right: 15px;
    }

    .portrait #vmt-15 .col-right {
        width: 100%;
        height: 994px;
        overflow: hidden;
        padding-left: 15px;
    }

    .portrait #vmt-15 .address-price-block {
        height: 300px;
    }

    /************  OPENN HOUSE
    **************/
    .portrait #vmt-15 .open-house-wrap {
        top: 495px;
        left: 0;
        right: 0;
    }

    .portrait #vmt-15 .open-house-wrap .open-house-inner {
        width: 40%;
        margin: 0 auto;
    }
/* added for demo */
    /* .portrait #vmt-13 .col-left,
    .portrait #vmt-13 .col-right
    {
        flex: 0 0 100%;
        max-width: 100%;
    } */
    
    .landscape #vmt-15 .col-xl-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    