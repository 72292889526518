.form {
    margin: 0 auto;
    z-index: 0;
    position: relative;
    top: -30px;
}
.pt-65
{
    padding-top: 65px !important;
}
.mt-15
{
    margin-top: 15px !important;
}
.form .form-block {
    background-color: white;
    border-radius: 10px;
    margin-bottom: 15px;
    padding: 35px 0 30px;
    overflow: hidden;
    width: 100%
}

.form .form-block .inner-form-block {
    width: calc(600px + 20px);
    max-width: 100%;
    padding: 0 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: auto;
}
.form .form-block .inner-form-block .logo-block {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column: 1 / 3;
    column-gap: 20px;
}

.form .form-block .inner-form-block .styling-title {
    font-weight: bold;
    border-bottom: 1px solid lightgrey;
    grid-column: 1 / 3;
    padding-bottom: 15px;
    font-size: 18px;
    margin: 0 -20px 20px;
    padding-left: 20px;
    padding-right: 20px;
}

.form .form-block .inner-form-block .styling-undertitle {
    font-weight: normal;
    padding-top: 5px;
    font-size: 10px;

}

.form .button {
    width: fit-content;
    text-align: center;
    padding: 10px 15px;
    margin: 15px auto 0;
    border-radius: 5px;
    background-color: white;
    cursor: pointer;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
}

.form .button:hover {
    color: grey !important;
}

.form .button.submit {
    width: 600px;
    background: #9dc22c;
    color: white;
    max-width: 90%;
}

.form .buttonsContainer {
    margin: auto;
    display: grid;
    width: 400px;
    max-width: calc(100vw - 60px);
}


.form .buttonsContainer .button.submit-small {
    background: #9dc22c;
    color: white;
}

.form .buttonsContainer .button.reset {
    box-shadow: none;
    background-color: transparent;
}

.form .previewContainer {
    width: 100%;
    margin: 0 auto 50px auto;
    text-align: center;
}

.form .previewTitle
{
    margin-bottom: 10px;
}

.form .preview, 
.form .preview[src=''] ~  .greyBlockForFireFoxSafari{
    height: 90px;
    max-width: 100%;
    margin: auto;
    display: block;
    background-color: lightgrey;  
    cursor: pointer;
}

/* Emty src, for Safari/Firefox, is standard in Edge/Chrome */
.form .preview[src='']
{
    display: none;
}

.greyBlockForFireFoxSafari{
    display: none;
}
/*  */
.form .preview:hover,
.form .preview[src=''] ~  .greyBlockForFireFoxSafari:hover {
    opacity: 0.5;
}

.blackBlock {
    cursor: pointer;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    backdrop-filter: blur(3px);
}

.invisibleForm {
    width: 400px;
    background-color: white;
    border-radius: 2px;
    padding: 30px 40px;
    z-index: 6;
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.75);
    text-align: left;
    max-width: 100vw;
}

.invisibleForm div.times
{
    height: 20px;
    display: block;
    width: 20px;
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: rgba(0, 0, 0, 0.3);
    cursor: pointer;
}

.invisibleForm div.times:hover
{
        background-color: rgba(0, 0, 0, 0.1);
}

.invisibleForm input:read-only {
    display: none !important;
}

.invisibleForm input[type='text'] {

}

.invisibleForm textarea {
    resize: vertical;
    min-height: 50px;
    width: 100%;
    max-height: 170px;
    border: 1px solid black;
    margin-top: -5px;
}

.textAboveForm,
.textBelowForm {
    text-align: left;
    margin-bottom: 10px;
    color: black;
    line-height: 20px;
}

.textAboveForm div b {
    line-height: 30px;
}

form button {
    margin-top: 50px;
    padding: 7px 15px;

    box-shadow: 0px 0px 20px -5px rgba(0,0,0,0.75);
    border: none;
    border-radius: 10px;
    background: #9dc22c;
    color: white;
    cursor: pointer;
}

form button:hover
{
    background: #1e9600;
}

form input[type='file'] {
  color: transparent;
  display: none;
}

.CreatePage form input[type='text'] {
  margin: 0 auto 15px auto;
}

/* #askCustomTemplateDiv .form input[type='text'] {
    
} */
form .url
{
    grid-column: 1/3;
    text-align: center;  
}
.form select{
    width: 190px;
}

@media(max-width: 580px){
    .form .form-block .inner-form-block {
        width: 100%;
        text-align: center;
    }
    .form .form-block .inner-form-block .logo-block {
        grid-template-columns: 1fr 1fr;
    }
    .form .form-block .inner-form-block .selectContainer,
    .form .form-block .inner-form-block select,
    .form .form-block .inner-form-block div.ColorSelector{

        grid-column: 1/3;
        width: 190px;
        margin: auto;
        text-align: left;
    }

    .form .form-block .inner-form-block div.ColorSelector
    {
        margin-top: 10px;
    }
    
    .form .form-block .inner-form-block .previewContainer
    {
        text-align: center;
        grid-column: 1/3;
        margin-bottom: 25px;
    }
    .CreatePage .form input[type='text'] {
        width: 80%;
    }
}