/********
    Datum: 13-03-2019
    Notes: Open Huis Changes
********/

/*************
STANDARD
**************/
#vmt-01 .container {
    width: 1920px;
    height: 1080px;
    max-width: none;
    padding: 15px;
    overflow: hidden;
}

#vmt-01 .overflow {
    width: 941px;
    height: 666px;
    z-index: 1;
    overflow: hidden;
    margin: 6px 0;
}

#vmt-01 .bg-img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

#vmt-01 .ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

#vmt-01 .extrapadding {
    padding: 30px;
}

/*************
    HOUSEDETAILS
**************/
#vmt-01 .status {
    font-weight: bold;
    position: absolute;
    padding: 10px 50px;
    font-size: 60px;
    text-transform: uppercase;
    margin-top: 210px;
    margin-left: -15px;
    top: 0;
    z-index: 1000;
}

#vmt-01 .price {
    font-size: 40px;
    padding-left: 30px;
}

#vmt-01 .details {
    width: 468.4px;
    height: 386px;
    font-size: 26px;
    line-height: 2.1;
    /* font-weight: bold; */
    margin: 4px 2px 0 0;
    padding: 30px 25px 0 25px;
    float: left;
}

#vmt-01 .house1 {
    width: 941px;
    height: 666px;
}

#vmt-01 .house2 {
    width: 468.4px;
    height: 328px;
    margin: 0 2px 2px 0;
    float: left;
}

#vmt-01 .house3 {
    width: 468.4px;
    height: 328px;
    margin: 0 0 2px 2px;
    float: left;
}

#vmt-01 .house4 {
    width: 468.4px;
    height: 328px;
    margin: 2px 2px 0 0;
    float: left;
}

#vmt-01 .house5 {
    width: 468.4px;
    height: 328px;
    margin: 2px 0 0 2px;
    float: left;
}

#vmt-01 .gmaps-block {
    width: 468.4px;
    height: 386px;
    margin: 4px 0 0 2px;
    float: left;
    overflow: hidden;
}

#vmt-01 .gmaps {
    width: 100%;
    height: 386px;
}

#vmt-01 .address-details-block {
    display: flex;
    align-items: center;
    width: 100%;
    height: 180px;
    padding-left: 40px;
    font-size: 34px;
    font-weight: 300;
    letter-spacing: 2px;
    font-weight: bold;
}

#vmt-01 .address-details {
    flex: 1;
}

#vmt-01 .street {
    max-width: 600px;
    margin-left: 8px;
}

#vmt-01 .house-number {
    max-width: 150px;
    margin-left: 8px;
}

#vmt-01 .city {
    max-width: 750px;
    margin-left: 8px;
}

/*************
    CUSTOM STYLING
**************/
#vmt-01 .arrow-right {
    width: 0;
    height: 0;
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
    border-left: 30px solid;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

#vmt-01 #marker {
    width: 100px;
    height: 80px;
}

#vmt-01 .price-logo {
    height: 192px;
}

#vmt-01 .price p {
    display: table-cell;
    vertical-align: middle;
}

#vmt-01 .col-left {
    padding-right: 4px;
}

#vmt-01 .header {
    font-size: 40px;
    line-height: 1.3;
}

#vmt-01 .logo {
    margin: auto;
    height: 100%;
/* not sure of onderstaand de oplossing is */
    max-width: 100%;
}

#vmt-01 .estate-agent {
    width: 300px;
    height: 130px;
}

#vmt-01 .border {
    width: 4px;
    height: auto;
    position: absolute;
    top: 30px;
    right: 0;
    bottom: 30px;
    left: 0;
    margin: auto;
}

/*************
    OPENN HOUSE
**************/
#vmt-01 .open-house-wrap {
    display: none;
}

#vmt-01 .open-house-wrap {
    position: absolute;
    bottom: 230px;
    left: 0;
}

#vmt-01 .open-house-wrap .open-house-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    line-height: 1.2;
    letter-spacing: 1px;
    background-color: #ff4729;
    color: white;
}

#vmt-01 .open-house-wrap .oh-title {
    font-size: 2.25rem;
    font-weight: 600;
    text-transform: uppercase;
}

#vmt-01 .open-house-wrap .oh-date {
    font-size: 2.1rem;
    font-weight: 300;
}

#vmt-01 .open-house-wrap .oh-time {
    font-size: 1.6rem;
    font-weight: 300;
}

/* #vmt-01 .poweredByViewie {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 180px;
    height: 40px;
} */



/* @media (orientation: portrait) { */

    /************  STANDARD
    **************/
    .portrait #vmt-01 .container {
        width: 1080px;
        height: 1920px;
        padding: 1%;
    }

    .portrait #vmt-01 .overflow {
        width: 1058px;
        height: 620px;
    }

    /************  HOUSEDETAILS
    **************/
    .portrait #vmt-01 .status {
        margin-top: 150px;
        margin-left: -11px;
        top: 0;
    }

    .portrait #vmt-01 .details {
        width: 527px;
        height: 366px;
    }

    .portrait #vmt-01 .house1 {
        width: 1058px;
        height: 620px;
    }

    .portrait #vmt-01 .house2 {
        width: 527px;
        height: 310px;
    }

    .portrait #vmt-01 .house3 {
        width: 527px;
        height: 310px;
    }

    .portrait #vmt-01 .house4 {
        width: 527px;
        height: 310px;
    }

    .portrait #vmt-01 .house5 {
        width: 527px;
        height: 310px;
    }

    .portrait #vmt-01 .price {
        width: 480px;
        height: 100px;
    }

    .portrait #vmt-01 .gmaps-block {
        width: 527px;
        height: 366px;
        float: right;
        overflow: hidden;
    }

    .portrait #vmt-01 .gmaps {
        width: 100%;
        height: auto;
    }

    .portrait #vmt-01 .address-details-block {
        height: 120px;
    }

    .portrait #vmt-01 .street {
        max-width: 700px;
    }

    .portrait #vmt-01 .city {
        max-width: 850px;
    }

    .portrait #vmt-01 #marker {
        margin-top: 15px;
    }

    /************  CUSTOM STYLING
    **************/
    .portrait #vmt-01 .price-logo {
        height: 143px;
    }

    .portrait #vmt-01 .col-left {
        padding-right: 0;
        width: 100%;
        height: 900px;
    }

    .portrait #vmt-01 .col-right {
        padding-left: 0;
        width: 100%;
        height: 994px;

    }

    /************  OPENN HOUSE
    **************/
    .portrait #vmt-01 .open-house-wrap {
        top: 575px;
        bottom: auto;
        left: 0;
    }
/* } */
    .portrait #vmt-01 .col-left,
    .portrait #vmt-01 .col-right
    {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }