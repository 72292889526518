/********
    Datum: 13-03-2019
    Notes: Open Huis Changes
********/

/*************
	STANDARD
**************/
#vmt-02 .container {
    max-width: none;
    width: 1920px;
    height: 1080px;

}

#vmt-02 .overflow {
    width: 100%;
    height: 498px;
    overflow: hidden;
}

#vmt-02 .bg-img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

#vmt-02 .ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

/*************
	HOUSEDETAILS
**************/
#vmt-02 .house1 {
    height: 498px;
    margin: 0;
}

#vmt-02 .house2 {
    height: 160px;
}

#vmt-02 .house3 {
    height: 160px;
}

#vmt-02 .house4 {
    height: 160px;
}

#vmt-02 .firsthouse,
#vmt-02 .secondhouse {
    padding: 0 60px;
    margin-top: -90px;
}

#vmt-02 .logo {
    height: 150px;
    width: 365px;
    position: absolute;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    top: 26px;
    right: 0;
    left: 0;
}

#vmt-02 .logo img {
    max-width: 100%;
    max-height: 150px;
    height: auto;
    width: 100%;
    z-index: 1000;
    position: relative;
}

#vmt-02 .status {
    z-index: 100;
    position: absolute;
    text-transform: uppercase;
    padding: 2px 10px;
    font-size: 44px;
    margin: 30px 0 0 -15px;
}

#vmt-02 .secondhouse .status {
    right: 0;
}

#vmt-02 .price {
    font-size: 35px;
    text-transform: none;
    letter-spacing: 0;
}

#vmt-02 .ask-price {
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 24px;
    display: table-cell;
    vertical-align: middle;
}

#vmt-02 .address-details {
    z-index: 100;
    position: relative;
    height: 90px;
    font-size: 28px;
    font-weight: 600;
    letter-spacing: 1px;
    font-weight: bold;
}

#vmt-02 .details {
    font-size: 18px;
    line-height: 1.74;
    font-weight: 400;
    padding-left: 15px;
    padding-right: 15px;
}
#vmt-02 .city
{
    /* text-transform: uppercase; */
}
#vmt-02 .house-block {
    width: 275px;
}

/*************
	CUSTOM STYLING
**************/
#vmt-02 .logo-wrap {
    width: 100%;
    height: 245px;
    position: absolute;
    z-index: 45;
    margin-top: -65px;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
}

#vmt-02 .svg-wrap {
    position: absolute;
    width: 485px;
    height: 230px;
    background: #ededed;
    border-radius: 50%;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    z-index: 1;
    margin: 0 auto;
    top: 15px;
    right: 0;
    left: 0;
}

#vmt-02 .tablestart {
    height: 200px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#vmt-02 .header-top {
    height: 36px;
}

#vmt-02 .bottom-footer {
    height: 34px;
    margin-top: 5px;
}

#vmt-02 .header {
    text-align: center;
    height: 133px;
    z-index: 45;
    position: relative;
    background-color: white;
}
#vmt-02 .svg-wrap
{
    background-color: white;
}

#vmt-02 .header-atback {
    text-align: center;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    height: 133px;
    z-index: 45;
    position: absolute;
    top: 35px;
    right: 0;
    left: 0;
}

#vmt-02 .object-pice-block {
    padding: 22px 0;
}

#vmt-02 .cborder {
    width: 1px;
}

#vmt-02 .price-block {
    height: 155px;
}

#vmt-02 .col-6.nopadding {
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%;
}

#vmt-02 .gallery {
    display: flex;
    justify-content: space-between;
    padding-top: .5rem;
    padding-bottom: .5rem;
}

#vmt-02 .nopadding {
    padding: 0;
}

/*************
    OPENN HOUSE
**************/
#vmt-02 .open-house-wrap {
    display: none;
}

#vmt-02 .open-house-wrap {
    position: absolute;
}

#vmt-02 .open-house-wrap.first-house {
    bottom: 425px;
    left: 50px;
}

#vmt-02 .open-house-wrap.second-house {
    bottom: 425px;
    right: 50px;
}

#vmt-02 .open-house-wrap .open-house-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    line-height: 1.2;
    letter-spacing: 1px;
    background-color: #ff4729;
    color: white;
}

#vmt-02 .open-house-wrap .oh-title {
    font-size: 2.1rem;
    font-weight: 600;
    text-transform: uppercase;
}

#vmt-02 .open-house-wrap .oh-date {
    font-size: 1.75rem;
    font-weight: 300;
}

#vmt-02 .open-house-wrap .oh-time {
    font-size: 1.5rem;
    font-weight: 300;
}
/* #vmt-02 .poweredByViewie
{
    position: fixed;
    bottom: 0;
    right: 0;
    width: 180px;
    height: 40px;
} */
#vmt-02 div>small
{
    position: relative;
    bottom: 15px;
    font-size: 25px;
}




    /************  STANDARD
    **************/
    .portrait #vmt-02 .container {
        width: 1080px;
        height: 1920px;
        padding: 0;
    }

    .portrait #vmt-02 .overflow {
        width: 748px;
        height: 522px;
        margin-bottom: 6px;
    }

    /************  HOUSEDETAILS
    **************/
    .portrait #vmt-02 .price {
        font-size: 36px;
    }

    .portrait #vmt-02 .house1 {
        height: 522px;
        width: 748px;
        margin: 0;
    }

    .portrait #vmt-02 .house2 {
        height: 170px;
        margin: 0px 0px 3px 6px;
    }

    .portrait #vmt-02 .house3 {
        height: 170px;
        margin: 3px 0px 3px 6px;
    }

    .portrait #vmt-02 .house4 {
        height: 170px;
        margin: 3px 0px 0px 6px;
    }

    .portrait #vmt-02 .firsthouse,
    .portrait #vmt-02 .secondhouse {
        margin-top: 0;
    }

    .portrait #vmt-02 .secondhouse .status {
        right: auto;
        left: 15px;
    }

    .portrait #vmt-02 .address-details {
        z-index: 100;
        position: relative;
        margin-top: 60px;
        height: 60px;
        font-size: 28px;
        font-weight: 600;
        letter-spacing: 1px;
    }

    /************  CUSTOM STYLING
    **************/
    .portrait #vmt-02 .gallery {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0 15px;
    }

    .portrait #vmt-02 .address-details.text-right {
        text-align: left !important;
    }

    .portrait #vmt-02 .obj-price {
        width: 972px;
        height: 200px;
    }

    .portrait #vmt-02 .col-6.nopadding {
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
    }

    .portrait #vmt-02 .col-pt-8 {
        flex: 0 0 75%;
        max-width: 75%;
        padding: 0 0 0 15px;
    }

    .portrait #vmt-02 .col-pt-4 {
        flex: 0 0 25%;
        max-width: 25%;
        padding: 0 15px 0 0;
    }

    .portrait #vmt-02 .bottom-footer {
        margin-top: 23px;
    }

    /************  OPENN HOUSE
    **************/
    .portrait #vmt-02 .open-house-wrap.first-house {
        top: 650px;
        right: auto;
        bottom: auto;
        left: 30px;
    }

    .portrait #vmt-02 .open-house-wrap.second-house {
        right: auto;
        bottom: 275px;
        left: 30px;
    }
