.ColorSelector
{
    position: relative;
}

/* All items except first 2 in block */
.ColorSelector:not(:nth-child(-n+3)),
form select#template_logo_nr
{
    margin-top: 20px
}

.ColorSelector .title
{
    position: absolute;
    top: -12px;
    font-size: 12px;
    color: #eb4b4b;
    pointer-events: none;
}

.ColorSelector input[type="text"],
.ColorSelector input[type="text"]:focus
{
    height: 26px;
    border: none ;
    outline: none !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing:border-box;
    text-transform: uppercase;
}

.ColorSelector input[type="color"]
{
    position: absolute;
    height: 25px;
    border: 0 !important;
    padding: 0px !important;
    width: 25px;
    cursor: pointer;
}

/* Just for firefox css */
@-moz-document url-prefix() { 
  .ColorSelector input[type="color"]{
     padding: 3px !important;
     width: 28px;
  }
}