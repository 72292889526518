.EditTemplatePage .preview-container.landscape
{
    height: 470px;
}
.EditTemplatePage .preview-container.portrait
{
    height: 570px;
}
.EditTemplatePage .templateContainer div.slide.rotation
{
  
    transform-origin: top left;
    display: inline-block;
    text-align: left;
    outline: 10px solid black;
    overflow: hidden;
}
.EditTemplatePage .landscape.templateContainer div.slide.rotation
{
    transform: scale(.35);
}
.EditTemplatePage .portrait.templateContainer div.slide.rotation
{
    transform: scale(.25);
}
.EditTemplatePage .landscape.templateContainer .center
{
    width: calc(1920px * 0.35);
    margin: auto;
}
.EditTemplatePage .portrait.templateContainer .center
{
    width: calc(1080px * 0.25);
    margin: auto;
}
@media(max-width: 970px){
    .EditTemplatePage .landscape.templateContainer
    {
        /* margin-left: 5%; */
    }
}


@media(max-width: 770px){
    .EditTemplatePage .preview-container.landscape
    {
        height: 300px;
    }
    .EditTemplatePage .landscape.templateContainer div.slide.rotation
    {
        /* transform: scale(calc((100vw - 200px) / 1920)); */
        transform: scale(0.2);

    }
    .EditTemplatePage .landscape.templateContainer .center
    {
        width: calc(1920px * 0.20);
    }
    
}
@media(max-width: 410px){
    .EditTemplatePage .preview-container.landscape
    {
        height: 220px;
    }
    .EditTemplatePage .landscape.templateContainer div.slide.rotation
    {
        /* transform: scale(calc((100vw - 200px) / 1920)); */
        transform: scale(0.12);

    }
    .EditTemplatePage .landscape.templateContainer .center
    {
        width: calc(1920px * 0.12);
    }

}