.CreatePage .confirmTransport
{
    background-color: white;
    height: 100vh;
    width: 100vw;
    position: absolute;
    z-index: 10;
    text-align: center;
    padding-top: 10vh;
}

.CreatePage .back2create
{
    position: absolute;
    bottom: 40px;
    left: 50px;
    cursor: pointer;
}
.CreatePage .back2create:hover
{
    color: grey;
}