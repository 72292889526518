
#vmts-01 .container {
    max-width: none;
    width: 1920px;
    height: 1080px;
    padding: 0px;
    display: flex;
    background-color: #ededed;
}

#vmts-01 .header-slide {
    position: fixed;
    height: 351px;
    width: 100%;
    top: 0;
}

#vmts-01 .header-slide .logo {
    /* filter: drop-shadow(0px 0px 3px #000); */
    position: fixed;
    height: 60px;
    top: 30px;
    right: 50%;
    transform: translateX(50%);
}

#vmts-01 .content-slide {
    display: flex;
    width: 1658px;
    height: 700px;
    margin: 286px auto 0 auto;
    justify-content: space-between;
}

#vmts-01 .content-slide .house {
    background-color: #fff;
    height: 100%;
    width: 790px;
    border-radius: 10px;
    position: relative;
}

#vmts-01 .content-slide .house .picture-container {
    height: 447px;
    width: calc(100% + 36px);
    position: relative;
    margin-left: -18px;
    margin-top: -169px;
}

#vmts-01 .content-slide .house .picture-container .side-pictures {
    height: 100%;
    width: 275px;
    position: absolute;
    right: 0;
}

#vmts-01 .content-slide .house .picture-container .main-picture {
    width: 532px;
    height: 100%;
    left: 0;
    position: absolute;
}

#vmts-01 .content-slide .house .picture-container .main-picture .bg-img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    border-radius: 10px;
    box-shadow: -10px 10px 30px rgba(0, 0, 0, 0.25);
}

#vmts-01 .content-slide .house .picture-container .side-pictures .s-img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 217px;
    position: absolute;
    border-radius: 10px;
    box-shadow: -10px 10px 30px rgba(0, 0, 0, 0.25);
}

#vmts-01 .content-slide .house .picture-container .side-pictures .s-img-top {
    top: 0;
}

#vmts-01 .content-slide .house .picture-container .side-pictures .s-img-bottom {
    bottom: 0;
}

#vmts-01 .content-slide .house .picture-container .main-picture .status {
    position: absolute;
    left: -15px;
    top: 14px;
    line-height: 59px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 24px;
    border-radius: 10px;
    color: #fff;
    box-shadow: -19px 18px 34.8px 5.2px rgba(2, 4, 47, 0.25);
    font-weight: bold;
    text-transform: uppercase;
}

#vmts-01 .content-slide .house .text-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 422px;
    padding: 50px 80px;
    line-height: 43px;
}

#vmts-01 .content-slide .house .text-container .price {
    margin-top: 10px;
    font-weight: bold;
    font-size: 25px;
    font-style: italic;
}

#vmts-01 .content-slide .house .text-container .address {
    font-weight: bold;
    font-size: 34px;
    color: black;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;

    max-height: 86px;
}

#vmts-01 .content-slide .house .text-container table {
    font-size: 20px;
    line-height: 48px;
    margin-top: 30px;
    color: #1e3052;
}

#vmts-01 .content-slide .house .text-container table th {
    width: 265px;
}

#vmts-01 .content-slide .house .text-container table td {
    font-weight: 300;
}
/* 
#vmts-01 .poweredByViewie {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 180px;
    height: 40px;
} */



    .portrait #vmts-01 .container {
        height: 1920px;
        width: 1080px;

    }

    .portrait #vmts-01 .header-slide .logo {
        top: 22.5px;
        height: 55px;
    }

    .portrait #vmts-01 .content-slide {
        width: 952px;
        display: block;
        align-items: normal;
        margin-top: 50px;
    }

    .portrait #vmts-01 .content-slide .house {
        width: 100%;
        margin-top: 210px;
        height: 700px;
        align-items: normal;
    }

    .portrait #vmts-01 .content-slide .house .picture-container {
        margin: 0;
        position: absolute;
        top: -163px;
        left: -18px;
    }

    .portrait #vmts-01 .content-slide .house .picture-container .main-picture {
        width: 610px;
    }

    .portrait #vmts-01 .content-slide .house .picture-container .side-pictures {
        width: 355px;
    }

    .portrait #vmts-01 .content-slide .house .text-container {
        height: 415px;
        padding-top: 50px;

    }

    .portrait #vmts-01 .content-slide .house .text-container table {
        font-size: 25px;

    }

