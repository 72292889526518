/*****************
GLOBAL ELEMENTS
*****************/

#vmts-02 .container {
    max-width: none;
    width: 1920px;
    padding: 0px;
    height: 1080px;
    display: flex;
    background-color: #ededed;
}

#vmts-02 .header-slide .logo {
    /* filter: drop-shadow(0px 0px 3px #000); */
    position: fixed;
    height: 55px;
    top: 25px;
    left: 50%;
    transform: translateX(-50%);
}

#vmts-02 .header-slide {
    position: fixed;
    height: 350px;
    width: 100%;
    top: 0;
}

#vmts-02 .content-slide {
    width: 1685px;
    margin: 131px 0 122px 130px;
}


#vmts-02 .content-slide .picture-container .main-picture .status {
    position: absolute;
    top: 15px;
    text-align: center;
    padding: 0 10px 0 10px;
    border-radius: 8px;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
}

#vmts-02 .content-slide .house .text-container .price {
    font-weight: bold;
    font-size: 18px;
    font-style: italic;
    margin-top: 10px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
}

#vmts-02 .content-slide .house .text-container .address {
    font-weight: bolder;
    color: black;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
}



/*****************
BIG HOUSE CONTENT
*****************/

#vmts-02 .b-house {
    height: 697px;
    width: 790px;
    margin-top: 155px;
    position: relative;
    display: inline-block;
    border-radius: 10px;
    background-color: white;
}

#vmts-02 .b-house .picture-container {
    width: 828px;
    height: 448px;
    position: relative;
    margin-left: -18px;
    margin-top: calc(-155px + -25px);
}

#vmts-02 .content-slide .b-house .picture-container .side-pictures {
    height: 100%;
    width: 276px;
    position: absolute;
    right: 0;
}

#vmts-02 .content-slide .b-house .picture-container .main-picture {
    width: 532px;
    height: 100%;
    left: 0;
    position: absolute;
}

#vmts-02 .content-slide .b-house .picture-container .main-picture .status {
    color: white;
    left: -10px;
    font-size: 24px;
    line-height: 59px;
}

#vmts-02 .content-slide .b-house .picture-container .main-picture .bg-img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 10;
    height: 100%;
    border-radius: 10px;
    box-shadow: -10px 10px 30px rgba(0, 0, 0, 0.25);
}

#vmts-02 .content-slide .b-house .picture-container .side-pictures .s-img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 10;
    width: 100%;
    height: 216px;
    position: absolute;
    border-radius: 10px;
    box-shadow: -10px 10px 30px rgba(0, 0, 0, 0.25);
}

#vmts-02 .content-slide .b-house .picture-container .side-pictures .s-img-top {
    top: 0;
}

#vmts-02 .content-slide .b-house .picture-container .side-pictures .s-img-bottom {
    bottom: 0;
}

#vmts-02 .content-slide .b-house .text-container {
    position: absolute;
    padding: 57px 40px 20px 85px;
    height: 429px;
    left: 0;
    bottom: 0;
    width: 100%;
}

#vmts-02 .content-slide .b-house .text-container .address {
    font-size: 34px;
    line-height: 42px
}

#vmts-02 .content-slide .b-house .text-container .price {
    font-size: 24px;

}

#vmts-02 .content-slide .b-house .text-container table {
    margin-top: 33px;
    line-height: 48px;
    font-size: 20px;
    color: #1e3052;
}

#vmts-02 .content-slide .b-house .text-container table th {
    width: 265px
}

/*****************
SMALL HOUSE CONTENT
*****************/
#vmts-02 .content-slide .s-houses {
    float: right;
    width: 50%;
}

#vmts-02 .content-slide .s-houses .house:nth-of-type(2) {
    margin-top: 97px;
}

#vmts-02 .content-slide .s-houses .house {
    background-color: white;
    height: 365px;
    width: 812px;
    z-index: 3;
    border-radius: 10px;
    position: relative;
    float: right;
    display: inline-block;
}

#vmts-02 .content-slide .s-houses .house .picture-container {
    height: 415px;
    width: 360px;
    position: relative;
    display: inline-block;
    margin-left: -25px;
    margin-top: -25px;
}

#vmts-02 .content-slide .s-houses .house .picture-container .bottom-pictures {
    height: 112px;
    width: 100%;
    position: absolute;
    bottom: 0;
}

#vmts-02 .content-slide .s-houses .house .picture-container .main-picture {
    width: 100%;
    height: 285px;
    top: 0;
    position: absolute;
}

#vmts-02 .content-slide .s-houses .house .picture-container .main-picture .status {
    left: -16px;
    font-size: 20px;
    line-height: 45px;
}

#vmts-02 .content-slide .s-houses .house .picture-container .main-picture .bg-img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 10;
    height: 100%;
    border-radius: 10px;
    box-shadow: -10px 10px 30px rgba(0, 0, 0, 0.25);
}

#vmts-02 .content-slide .s-houses .house .picture-container .bottom-pictures .s-img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 10;
    width: 171px;
    height: 100%;
    position: absolute;
    border-radius: 10px;
    box-shadow: -10px 10px 30px rgba(0, 0, 0, 0.25);
}

#vmts-02 .content-slide .s-houses .house .picture-container .bottom-pictures .s-img-left {
    left: 0;
}

#vmts-02 .content-slide .s-houses .house .picture-container .bottom-pictures .s-img-right {
    right: 0;
}

#vmts-02 .content-slide .s-houses .house .text-container {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 478px;
    height: 100%;
    padding: 50px 30px 20px 54px;
}

#vmts-02 .content-slide .s-houses .house .text-container .address {
    font-size: 24px;
    line-height: 30px
}

#vmts-02 .content-slide .s-houses .house .text-container table {
    margin-top: 22px;
    font-size: 20px;
    line-height: 46px;
    color: #1e3052;
}

#vmts-02 .content-slide .s-houses .house .text-container table th {
    width: 215px
}
/* 
#vmts-02 .poweredByViewie {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 180px;
    height: 40px;
} */



    .portrait #vmts-02 .container {
        max-width: none;
        width: 1080px;
        height: 1920px;
    }

    .portrait #vmts-02 .container .content-slide {
        width: 950px;
        margin: calc(100px + 25px) auto 0 auto;
    }

    .portrait #vmts-02 .header-slide .logo {
        top: 22.5px;
    }

    .portrait #vmts-02 .content-slide .b-house {
        display: flow-root;
        width: 100%;
        height: 700px;
        background-color: white;
    }

    .portrait #vmts-02 .b-house .picture-container {
        width: calc(100% + 50px);
        position: relative;
        margin-left: -25px;

    }

    .portrait #vmts-02 .content-slide .b-house .picture-container .main-picture {
        width: 665px;
    }

    .portrait #vmts-02 .content-slide .b-house .picture-container .side-pictures {
        width: 310px;
    }

    .portrait #vmts-02 .content-slide .b-house .text-container {
        height: 430px;
    }

    .portrait #vmts-02 .content-slide .b-house .text-container table {

        font-size: 25px;
    }

    .portrait #vmts-02 .content-slide .s-houses {
        float: left;
        width: 100%;
    }

    .portrait #vmts-02 .content-slide .s-houses .house .picture-container .main-picture .status {
        left: -10px;
    }

    .portrait #vmts-02 .content-slide .s-houses .house,
    .portrait #vmts-02 .content-slide .s-houses .house:nth-of-type(2) {
        margin-top: 75px;
        width: 100%;
    }

    .portrait #vmts-02 .content-slide .s-houses .house .text-container {
        width: 617px;
    }

