/********
    Datum: 14-03-2019
    Notes: Open Huis Changes
********/

/*************
    STANDARD
**************/
#vmt-03 .container {
    max-width: none;
    width: 1920px;
    height: 1080px;
}

#vmt-03 .overflow {
    width: 666px;
    height: 460px;
    z-index: 10;
    overflow: hidden;
}

#vmt-03 .bg-img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

#vmt-03 .ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

#vmt-03 .extrapadding {
    padding: 40px;
}

/*Shit*/
#vmt-03 .spacing {
    display: block;
}

#vmt-03 .spacing.spacing-sm {
    height: 30px;
}

#vmt-03 .spacing.spacing-md {
    height: 40px;
}

#vmt-03 .spacing.spacing-lg {
    height: 90px;
}

/*************
    HOUSEDETAILS
**************/
#vmt-03 .status {
    font-weight: bold;
    position: absolute;
    padding: 1% 3%;
    font-size: 2em;
    text-transform: uppercase;
    margin-top: 1%;
    z-index: 1000;
}

#vmt-03 .pricing {
    position: absolute;
    opacity: 0.85;
    bottom: 0;
    width: 666px;
    height: 66px;
    line-height: 1.6;
    font-size: 43px;
    text-align: center;
}

#vmt-03 .price {
    position: absolute;
    bottom: 0;
    width: 666px;
    line-height: 1.6;
    font-size: 43px;
    text-align: center;
}

#vmt-03 .details {
    font-size: 30px;
    line-height: 2;
    font-weight: 300;
}

#vmt-03 .house0 {
    height: 460px;
    width: 666px;
    margin-right: 5px;
}

#vmt-03 .house1 {
    height: 150px;
    margin-bottom: 5px;
}

#vmt-03 .house2 {
    height: 150px;
}

#vmt-03 .house3 {
    height: 150px;
    margin-top: 5px;
}

#vmt-03 .firsthouse {
    width: 50%;
    float: left;
}

#vmt-03 .secondhouse {
    width: 50%;
    float: right;
}

#vmt-03 .middle {
    display: none
}

#vmt-03 .row.bottom {
    display: block;
    bottom: 0;
    position: absolute;
}

#vmt-03 .address-details {
    flex: 1;
    font-weight: bold;
}

#vmt-03 .street {
    max-width: 470px;
    margin-left: 8px;
}

#vmt-03 .house-number {
    max-width: 120px;
    margin-left: 8px;
}

#vmt-03 .city {
    max-width: 750px;
    margin-left: 8px;
}

/*************
    CUSTOM STYLING
**************/
#vmt-03 .topbar {
    font-size: 34px;
    padding: 12px 20px;
}

#vmt-03 .middlebar {
    width: 1920px;
    height: 180px;
    padding: 20px 40px;
}

#vmt-03 .logo {
    /* width: 100%; */
    height: 100%;
}

/*************
    OPENN HOUSE
**************/
#vmt-03 .open-house-wrap {
    display: none;
}

#vmt-03 .open-house-wrap {
    position: absolute;
}

#vmt-03 .open-house-wrap.first-house {
    bottom: 580px;
    left: 55px;
}

#vmt-03 .open-house-wrap.second-house {
    bottom: 580px;
    left: 1000px;
}

#vmt-03 .open-house-wrap .open-house-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    line-height: 1.2;
    letter-spacing: 1px;
    background-color: #ff4729;
    color: white;
}

#vmt-03 .open-house-wrap .oh-title {
    font-size: 1.65rem;
    font-weight: 600;
    text-transform: uppercase;
}

#vmt-03 .open-house-wrap .oh-date {
    font-size: 1.45rem;
    font-weight: 300;
}

#vmt-03 .open-house-wrap .oh-time {
    font-size: 1.25rem;
    font-weight: 300;
}



    /************  STANDARD
    **************/
    .portrait #vmt-03 .container {
        width: 1080px;
        height: 1920px;
    }

    .portrait #vmt-03 .extrapadding {
        padding: 40px;
    }

    .portrait #vmt-03 .overflow {
        height: 499px;
        width: 745px;
    }

    /************  HOUSEDETAILS
    **************/
    .portrait #vmt-03 .details {
        font-size: 29px;
        height: 116px;
    }

    .portrait #vmt-03 .street {
        max-width: 500px;
    }

    .portrait #vmt-03 .house0 {
        width: 745px;
        height: 499px;
    }

    .portrait #vmt-03 .house1 {
        height: 163px;
    }

    .portrait #vmt-03 .house2 {
        height: 163px;
    }

    .portrait #vmt-03 .house3 {
        height: 163px;
    }

    .portrait #vmt-03 .pricing {
        width: 745px;
    }

    .portrait #vmt-03 .price {
        width: 745px;
    }

    .portrait #vmt-03 .firsthouse {
        width: 100%;
    }

    .portrait #vmt-03 .secondhouse {
        position: absolute;
        width: auto;
        bottom: 16px;
        left: 15px;
        right: 15px;
    }

    .portrait #vmt-03 .logo {
        height: 140px;
    }

    /************  CUSTOM STYLING
    **************/
    .portrait #vmt-03 .middle {
        position: absolute;
        display: block;
        top: 0;
        bottom: 0;
        margin: auto -15px;
        width: 100%;
        height: 236px;
    }

    .portrait #vmt-03 .middlebar {
        width: 1080px;
        height: 236px;
        padding: 35px 40px;
    }

    .portrait #vmt-03 .row.bottom {
        display: none;
    }

    .portrait #vmt-03 .details {
        font-size: 28px;
    }

    .portrait #vmt-03 .details .row {
        width: 50%;
        float: left;
    }

    .portrait #vmt-03 .details .col-6 {
        line-height: 2.3;
    }

    .portrait #vmt-03 .details .col-6:nth-child(1) {
        width: 20%;
    }

    .portrait #vmt-03 .details .col-6:nth-child(2) {
        width: 30%;
    }

    .portrait #vmt-03 .details .col-6:nth-child(3) {
        width: 30%;
    }

    .portrait #vmt-03 .details .col-6:nth-child(4) {
        width: 20%;
    }

    .portrait #vmt-03 .details .col-6:nth-child(5) {
        width: 20%;
    }

    .portrait #vmt-03 .details .col-6:nth-child(6) {
        width: 30%; overflow: hidden;
    }

    .portrait #vmt-03 .details .col-6:nth-child(7) {
        width: 30%;
    }

    .portrait #vmt-03 .details .col-6:nth-child(8) {
        width: 20%;
    }


    /************  OPENN HOUSE
    **************/
    .portrait #vmt-03 .open-house-wrap.first-house {
        top: 405px;
        bottom: auto;
        left: 55px;
    }

    .portrait #vmt-03 .open-house-wrap.second-house {
        bottom: 340px;
        left: 55px;
    }
